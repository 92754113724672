import React, { useState } from 'react';
import './curriculumCard.scss';
import ARROW from "../../assets/chevron-right.svg";
import DOWN_ARROW from "../../assets/chevron-down.svg";

const CurriculumCard = ({ module }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        // console.log("Current state before toggle: ", isOpen);
        setIsOpen(!isOpen);
        // console.log("Toggling open state for module: ", module.moduleName); // Adjusted to moduleName
        // console.log("Current state after toggle: ", !isOpen);
    };

    return (
        <div className='curriculum-card'>
            <div className='module-info' onClick={toggleOpen}>
                <div className='leftside-curriculumcard'>
                    <div className='toggle-button'>
                        {isOpen ? <img src={DOWN_ARROW} alt="downward arrow" /> : <img src={ARROW} alt="right arrow" />}
                    </div>
                    <h2 className='title'>{module.moduleName}</h2>
                </div>
                <div className='right-side-curriculumcard'>
                    <span className='module-no'>Module {module.level}</span>
                </div>
            </div>
            {isOpen && (
                <div className='module-contents' style={{ display: isOpen ? 'block' : 'none' }}> 
                    <ul>
                        {module.topics.map((topic, index) => (
                            <li key={index}>
                                <span className='lesson-no'>LO{index + 1}</span>
                                <span className='lesson-name'>{topic}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}

export default CurriculumCard;
