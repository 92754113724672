import React, { useState,useContext, useRef, useEffect } from 'react';
import "./CourseContent.scss";
import VIDEO1 from "../../assets/course_videos/12941383-hd_1920_1080_30fps.mp4";
import { Link } from 'react-router-dom';
import CourseContext from '../../utils/courseContext';
function CourseContent() {
  const chapterName = "Chapter 1: Introduction";
  const resourceURL = "https://github.com/manideeprkummitha";
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1); // Default volume level 100%
  const [prevVolume, setPrevVolume] = useState(1); // To remember volume before mute
  const [playbackRate, setPlaybackRate] = useState(1); // Default playback rate is normal speed
  const [currentTime, setCurrentTime] = useState(0); // Current time of the video
  const [duration, setDuration] = useState(0); // Total duration of the video
  const { selectedLesson } = useContext(CourseContext);

   useEffect(() => {
        if (selectedLesson && videoRef.current) {
            videoRef.current.src = selectedLesson.video; // Use the video URL from the selected lesson
        }
    }, [selectedLesson]);

  useEffect(() => {
    setDuration(videoRef.current.duration);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowLeft":
          skipBackward();
          break;
        case "ArrowRight":
          skipForward();
          break;
        case " ":
          event.preventDefault(); // Prevent the default action to stop scrolling
          togglePlayPause();
          break;
        case "m":
        case "M":
          toggleMute();
          break;
        default:
          break;
      }
    };

    // Add event listener for keyboard controls
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isPlaying]); // Make sure to use the latest state

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const skipForward = () => {
    videoRef.current.currentTime += 10;
  };

  const skipBackward = () => {
    videoRef.current.currentTime -= 10;
  };

  const toggleMute = () => {
    const currentlyMuted = volume === 0;
    setVolume(currentlyMuted ? prevVolume : 0);
    setPrevVolume(currentlyMuted ? prevVolume : volume);
    videoRef.current.volume = currentlyMuted ? prevVolume : 0;
  };

  const changeVolume = (e) => {
    const newVolume = parseFloat(e.target.value);
    videoRef.current.volume = newVolume;
    setVolume(newVolume);
    setPrevVolume(newVolume);
  };

  const changePlaybackRate = (newRate) => {
    setPlaybackRate(newRate);
    videoRef.current.playbackRate = newRate;
  };

  const handleProgressChange = (e) => {
    const newTime = parseFloat(e.target.value);
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  useEffect(() => {
    const video = videoRef.current;
    const updateProgress = () => setCurrentTime(video.currentTime);
    video.addEventListener('timeupdate', updateProgress);
    return () => video.removeEventListener('timeupdate', updateProgress);
  }, []);

  const formatTime = (seconds) => {
    const padded = (num) => String(num).padStart(2, '0');
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsLeft = Math.floor(seconds % 60);
    return `${hours > 0 ? `${padded(hours)}:` : ''}${padded(minutes)}:${padded(secondsLeft)}`;
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      videoRef.current.parentNode.requestFullscreen().catch(err => {
        alert(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
      });
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  return (
    <div className='coursecontent'>
      <div className='container'>
        <div className='chaptername'>
          <span className='name'>{selectedLesson ? selectedLesson.lessonName : "Select a lesson"}</span>
        </div>
        <div className='video-content'>
          <video ref={videoRef} src={VIDEO1} width="90%"/>
          <div className='controls'>
            <button onClick={togglePlayPause}>
              {isPlaying ? 'Pause' : 'Play'}
            </button>
            <button onClick={skipBackward}>-10s</button>
            <button onClick={skipForward}>+10s</button>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={changeVolume}
              className="volume-slider"
            />
            <select
              className="playback-rate-selector"
              value={playbackRate}
              onChange={(e) => changePlaybackRate(parseFloat(e.target.value))}
            >
              <option value="0.5">0.5x</option>
              <option value="1">1x</option>
              <option value="1.25">1.25x</option>
              <option value="1.5">1.5x</option>
              <option value="1.75">1.75x</option>
              <option value="2">2x</option>
            </select>
            <button onClick={toggleFullscreen}>Fullscreen</button>
            <div className="time-display">
              {formatTime(currentTime)} / {formatTime(duration)}
            </div>
            <input
              type="range"
              className="progress-bar"
              min="0"
              max={duration || 1}
              value={currentTime}
              onChange={handleProgressChange}
            />
          </div>
        </div>
        <div className='buttons'>
          <Link to={resourceURL} target="_blank" rel="noopener noreferrer" className='resource-button'>
            View Resources
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CourseContent;
