// CourseCurriculum.js
import React from 'react';
import CurriculumCard from '../curriculumCard/CurriculumCard';
import "./CourseCurriculum.scss";

const CourseCurriculum = ({ modules = [] }) => {
  return (
    <div className='curriculum'>
      <div className='container'>
        <h2>Course Curriculum</h2>
        <div className='curriculum-part'>
          {modules.map((module, index) => (
            <CurriculumCard key={index} module={module} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CourseCurriculum;
