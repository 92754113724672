import React from 'react';
import "./Companies.scss";
import spotify from "../../assets/spotify.svg";
import google from "../../assets/google.svg";
import coinbase from "../../assets/coinbase.svg";
import dropbox from "../../assets/dropbox.svg";
import atlassian from "../../assets/atlassian.svg";

function Companies() {
  return (
    <div className='companies'>
      <div className='container'>
        <h2>Big industry companies</h2>
        <div className='company-images'>
           <img src={spotify} alt=""/>
           <img src={google} alt=""/>
           <img src={coinbase} alt=""/>
           <img src={dropbox} alt=""/>
           <img src={atlassian} alt=""/>
        </div>
      </div>
    </div>
  )
}

export default Companies
