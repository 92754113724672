import React from 'react';
import { Link } from 'react-router-dom';
import "./refundpolicy.scss"; // Import your stylesheet for RefundPolicy

function RefundPolicy() {
  return (
    <div className="refund-policy">
      <div className="container">
        <div className="content">
          <span className='title'>Cancellation / Refund Policy for DataStudyGuide</span>
          <p>
            DataStudyGuide offers flexible payment options, including full upfront payments and installment plans (EMIs) for our courses.
          </p>

          <h1>1. Refund Eligibility</h1>
          <p>
            Refund requests can be considered under the following conditions:
            <ul>
              <li>You must contact us at info@datastudyguide.com within the first 2 live classes of the course.</li>
              <li>All refund requests will be evaluated by DataStudyGuide Management on a case-by-case basis.</li>
              <li>The approval of a refund will be subject to the mode of payment and the options provided by the payment processor.</li>
            </ul>
          </p>

          <h1>2. Payment Security</h1>
          <p>
            DataStudyGuide adheres to strict security standards and does not store any of your sensitive payment information, including debit card, credit card, net banking details, or any other information restricted by relevant financial regulations. We have partnered with trusted payment gateways to securely process payments for our services.
          </p>

          <h1>3. Issue Resolution</h1>
          <p>
            Any issues related to payments, refunds, or cancellations will be addressed on an individual basis, and DataStudyGuide's decision will be considered final and binding.
          </p>

          <h1>4. Contact Us</h1>
          <p>
            For any inquiries or concerns regarding payments or refunds, please reach out to us at <Link to="/support">info@datastudyguide.com</Link>.
          </p>
        </div>

        <div className="connectButton">
          <button className="connectbutton">
            <Link to="/" className="link">Back to Home</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default RefundPolicy;