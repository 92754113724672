import React, { useState } from 'react';
import axios from 'axios'; // Make sure to import axios
import { Menu } from "@mui/icons-material";
import "./navbar.scss";
import Logo from "../../assets/logo.svg";
import { useNavigate, useLocation, NavLink } from 'react-router-dom'; 
import { useGoogleOneTapLogin } from '@react-oauth/google'; // Import the hook
import { useAuth } from '../../context/AuthContext';
function NavbarComponent() {
  const { currentUser, logout } = useAuth();
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [loginError, setLoginError] = useState(''); // State for handling login errors
  const navigate = useNavigate(); // Hook for navigation
  const clientId = '1072310299085-ltdcv83tk9a5bbd305thdf9s0vi2iphh.apps.googleusercontent.com';
  const location = useLocation(); 

 // Determine if the current page is the home page
 const isHomePage = location.pathname === '/';

 const handleLogout = async () => {
  try {
    await logout();
    navigate('/'); // Redirect to home page after logout
  } catch (error) {
    // console.error('Logout failed:', error);
    // Handle logout error
  }
};

  return (
    <div>
{/* <div id="one-tap-container" style={{ position: 'absolute', top: 0, left: 0, width: '100%', zIndex: 9999999  }}></div> */}
           <div className='navbar'>
      <div className='container'>
        <div className='logo-container'>
          <NavLink to="/" exact>
            <img src={Logo} alt="logo" className='logo'/>
          </NavLink>       
        </div>
        <div className='menu-icon'>
          <button className="menu-button" onClick={() => setShowMediaIcons(!showMediaIcons)}>
            <Menu/>
          </button>
        </div>
        <div className={`navlinks ${showMediaIcons ? 'active' : ''}`}>
          <div className='nav-links'>
            <ul>
              <li>
                <NavLink to="/" exact className='link' activeClassName='active'>Home</NavLink>
              </li>
              <li>
                <NavLink to="/courses" className='link' activeClassName='active'>Courses</NavLink>
              </li>
              {isHomePage && (
                <li>
                  <a href="#testimonials" className='link'>Testimonials</a>
                </li>
              )}
            </ul>
          </div>
          <div className='nav-buttons'>
          {!currentUser && (
              <>
                <NavLink to="/login" className='button-link login-button'>
                  <button className='button'>Login</button>
                </NavLink>
                <NavLink to="/register" className='button-link register-button'>
                  <button className='button'>Register</button>
                </NavLink>
              </>
            )}
            {currentUser && (
              <>
              <NavLink to="/dashboard/courses" className='button-link login-button'>
                <button className='button'>Dashboard</button>
              </NavLink>
                <button className='logout-button' onClick={handleLogout}>Logout</button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
    </div>
    
  )
}

export default NavbarComponent;
