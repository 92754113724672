import React from 'react'
import "./TestimonialCard.scss"
function TestimonialCard({ image, name, role, review, rating }) {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5 ? '★' : ''; // Add a half star if the decimal part is >= 0.5
    const emptyStars = '☆'.repeat(5 - fullStars - (halfStar ? 1 : 0));
    const starRating = '★'.repeat(fullStars) + halfStar + emptyStars;
  
  return (
    <div className='testimonial-card'>
      <div className='testimonial-content-part'>
        <div className='testimonial-content'>
            <p className="testimonial-review">{review}</p>
        </div>
        <div className="testimonial-rating">{starRating}</div>
      </div>
      <div className='testimonial-user-details'>
        <div className='user-image'>
            <img src={image} alt={name} className="testimonial-user-image" />
        </div>
        <div className='user-details'>
            <span className='user-name'>{name}</span>
            <span className='user-role'>{role}</span>
        </div>
      </div>
    </div>
  )
}

export default TestimonialCard
