import React from 'react';
import TestimonialCard from '../testimonialCard/TestimonialCard.jsx'; // Make sure this import path is correct
import "./testimonials.scss";
import UserImage from "../../assets/user.svg"; // Replace with the path to your user image asset

// Example testimonial data array
const testimonials = [
  {
    image: UserImage,
    name: "Ayush",
    role: "College student",
    review: "I  feel buying this course as my best investment in my life, Ayush makes good content, and solves any doubt if we ask in great detail in discord, which helped me a lot. He also includes industry relevant content that are needed to become job-ready after the course.",
    rating: 4.5,
  },
  {
    image: UserImage,
    name: "Mahesh",
    role: "College student",
    review: "At first, I was really scared about how the course was going to be. I thought it would be similar to other courses with some theory, coding, a project, and then the course would end. However, day by day, I am becoming increasingly interested in this course, and I'm feeling much better and happier that I enrolled in this Machine Learning program.",
    rating: 4.5,
  },
  {
    image: UserImage,
    name: "Arjun",
    role: "College student",
    review: "Enrolling with this program was a game-changer for me. The innovative approach to learning has redefined my educational journey. The comprehensive curriculum and exceptional instructors have expanded my knowledge and skills significantly. Thanks to them, I now approach challenges with confidence and look forward to a brighter future filled with endless opportunities in the data industry.",
    rating: 4.5,
  },
  // ...add more testimonial objects as needed
];

function Testimonials() {
  return (
    <div className='testimonials' id="testimonials">
      <div className='container'>
        <h2>Success Stories</h2>
        <div className='testimonialCards'>
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              image={testimonial.image}
              name={testimonial.name}
              role={testimonial.role}
              review={testimonial.review}
              rating={testimonial.rating}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
