import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './context/AuthContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId = '1072310299085-ltdcv83tk9a5bbd305thdf9s0vi2iphh.apps.googleusercontent.com' >
      <AuthProvider> 
        <App />
      </AuthProvider>   
    </GoogleOAuthProvider>
  </React.StrictMode>
);

