import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./Settings.scss";

function Settings() {
  const [activeTab, setActiveTab] = useState('general');
  const [userData, setUserData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
  });
  const [isPhoneNumberEdited, setIsPhoneNumberEdited] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      // console.log('Fetching user data...');
      const token = localStorage.getItem('accessToken');
      try {
        const response = await axios.get(`https://dsgbackend.azurewebsites.net/api/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log('User data fetched successfully:', response.data);
        setUserData({
          fullName: response.data.name,
          email: response.data.email,
          // Check if the phone number is 'N/A' or not set; otherwise, use the existing number
          phoneNumber: response.data.phoneNumber === 'N/A' ? '' : response.data.phoneNumber,
        });
        // If the phone number is not 'N/A', it's considered not editable
        setIsPhoneNumberEdited(response.data.phoneNumber === 'N/A');
      } catch (error) {
        // console.error('Error fetching user data:', error);
        setMessage('Error fetching user data');
      }
    };

    fetchUserData();
  }, []);

  const handlePhoneNumberChange = (e) => {
    setUserData({ ...userData, phoneNumber: e.target.value });
    setIsPhoneNumberEdited(true); // Flag as edited to show the update button
  };

  const handleUpdatePhoneNumber = async () => {
    // console.log('Updating phone number...');
    const token = localStorage.getItem('accessToken');
    // Replace ':id' with the actual user ID in your request URL
    const userId = 'your_user_id'; // You'll need to obtain the actual user ID
    try {
      const response = await axios.put(`https://dsgbackend.azurewebsites.net/api/users/update/${userId}`, {
        phoneNumber: userData.phoneNumber,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      // console.log('Phone number update successful:', response.data);
      setMessage('Phone number updated successfully.');
      setIsPhoneNumberEdited(false); // Reset the editing flag upon successful update
    } catch (error) {
      // console.error('Error updating phone number:', error);
      setMessage('Error updating phone number.');
    }
  };

  // Show update button only if the phone number is 'N/A' or has been edited
  const showUpdateButton = !userData.phoneNumber || isPhoneNumberEdited;
  // Make input read-only if there's an existing number and it's not being edited
  const isPhoneNumberReadOnly = userData.phoneNumber && !isPhoneNumberEdited;

  return (
    <div className='settings'>
      <div className='container'>
        <div className='tabs'>
          <div
            className={`tab ${activeTab === 'general' ? 'active' : ''}`}
            onClick={() => setActiveTab('general')}
          >
            General
          </div>
        </div>
        <div className='tab-content'>
          {activeTab === 'general' && (
            <div className="general-settings">
              <label>
                Full name *
                <input type="text" value={userData.fullName} readOnly />
              </label>
              <label>
                Email Address *
                <input type="email" value={userData.email} readOnly />
              </label>
              <label>
                Phone number (include country code)
                <input 
                  type="tel" 
                  value={userData.phoneNumber || ''} 
                  onChange={handlePhoneNumberChange}
                  readOnly={isPhoneNumberReadOnly}
                />
              </label>
              {showUpdateButton && (
                <button onClick={handleUpdatePhoneNumber}>Update</button>
              )}
              {/* {message && <p>{message}</p>} */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Settings;
