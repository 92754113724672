import React from 'react';
import "./Whatyoulearn.scss";
import check_square from "../../assets/chaeck-square.svg"; // Ensure the image path is correct
import learningPoints from '../../data'; // Ensure the data file path is correct

function Whatyoulearn() {
  // Split the learning points into two groups
  const firstColumnPoints = learningPoints.slice(0, 5);
  const secondColumnPoints = learningPoints.slice(5);

  return (
    <div className='whatyoulllearn'>
      <div className='container'>
        <h2>What you will learn</h2>
        <div className='points-container'> {/* Additional container for layout */}
          <ul className='points'> {/* First column */}
            {firstColumnPoints.map((point, index) => (
              <li key={index} className='point'>
                <img src={check_square} alt="Checkmark" />
                <span>{point}</span>
              </li>
            ))}
          </ul>
          <ul className='points'> {/* Second column */}
            {secondColumnPoints.map((point, index) => (
              <li key={index} className='point'>
                <img src={check_square} alt="Checkmark" />
                <span>{point}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Whatyoulearn;
