import React, { useState, useEffect } from 'react';
import "./Sidebar.scss";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import HOMEICON from "../../../assets/home.svg";
import COURSES from "../../../assets/courses.svg";
import PURCHASES from "../../../assets/purchase.svg";
import SETTINGS from "../../../assets/settings.svg";
import LOGOUT from "../../../assets/logout.svg";
import { useAuth } from '../../../context/AuthContext';
import CourseContentSidebar from '../coursecontentsidebar/CourseContentSidebar';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import MenuIcon from '@mui/icons-material/Menu';
import DataStudyGuide from "../../../assets/logo.svg";

function Sidebar({ isVisible, toggleSidebar }) {
    const navigate = useNavigate();
    const { logout, currentUser } = useAuth();
    const [userData, setUserData] = useState({});

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                if (!token) {
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                const response = await axios.get(`https://dsgbackend.azurewebsites.net/api/me`, config);
                setUserData(response.data);
            } catch (error) {
                console.error('Failed to fetch user data:', error);
            }
        };

        fetchUserData();
    }, [currentUser]);

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <div className={`sidebar-content ${isVisible ? 'visible' : 'hidden'}`}>
            <div className='sidebar-container'>
                <div className='top-menu'>
                    <div className='logo'>
                        <MenuIcon className='menu-icon' onClick={toggleSidebar} />
                        <Link to="/"> <img className='logoimage' src={DataStudyGuide} alt="" /> </Link>
                    </div>
                    <div className='sidebar-menu'>
                        <h2>Main Menu</h2>
                        <Link to="/dashboard/courses" className='menu-item'>
                            <img src={COURSES} /> Courses</Link>
                    </div>
                    <div className='sidebar-user-menu'>
                        {/* <Link to="/dashboard/purchase" className='menu-item'>
                            <img src={PURCHASES} /> Purchases</Link> */}
                        <Link to="/dashboard/settings" className='menu-item'>
                            <img src={SETTINGS} /> Settings</Link>
                        <Link onClick={handleLogout} className='menu-item'>
                            <img src={LOGOUT} />Logout</Link>
                    </div>
                </div>
                <div className='user-account'>
                    <AccountCircleRoundedIcon className='account' />
                    <span className='username'>{userData.name}</span>
                    <span className='useremail'>{userData.email}</span>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
