import React from 'react';
import { Link } from 'react-router-dom';
import "./privacypolicy.scss"; // Import your stylesheet for PrivacyPolicy

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <div className="container">
        <div className="content">
          <span className="title">Privacy Policy for Data Study Guide Website</span>
          <p>
            Effective Date: [24/03/2024]
          </p>
          <p>
            Welcome to Data Study Guide's Data Study Guide Website ("the Website"). At Data Study Guide, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our Website. By accessing or using the Website, you consent to the practices described in this Privacy Policy.
          </p>

          <span className='subtitle'>1. Information We Collect</span>
          <p>
            1.1 <strong>Personal Information:</strong> We may collect personal information that you provide to us voluntarily, such as your name, email address, and other contact information when you sign up for our services, request information, or communicate with us.
          </p>
          <p>
            1.2 <strong>Usage Information:</strong> We automatically collect certain information when you use the Website, including your IP address, browser type, operating system, referring URLs, and usage patterns.
          </p>
          <p>
            1.3 <strong>Cookies and Tracking Technologies:</strong> We may use cookies and similar tracking technologies to enhance your experience on the Website. You can control cookies through your browser settings, but disabling them may affect the functionality of the Website.
          </p>

          <span className='subtitle'>2. How We Use Your Information</span>
          <p>
            We may use your information for the following purposes:
          </p>
          <p>
            2.1 <strong>Providing Services:</strong> To provide you with the services offered on the Website, including study guides, resources, and information related to data bootcamps.
          </p>
          <p>
            2.2 <strong>Communication:</strong> To communicate with you, respond to your inquiries, and send you updates and notifications related to our services and offerings.
          </p>
          <p>
            2.3 <strong>Improvement:</strong> To improve the Website, analyze user behavior, and personalize your experience.
          </p>
          <p>
            2.4 <strong>Legal Compliance:</strong> To comply with legal obligations and enforce our Terms of Use.
          </p>

          <span className='subtitle'>3. Information Sharing</span>
          <p>
            We may share your information in the following circumstances:
          </p>
          <p>
            3.1 <strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in providing and maintaining the Website.
          </p>
          <p>
            3.2 <strong>Legal Requirements:</strong> We may disclose your information in response to lawful requests by public authorities or to comply with legal obligations.
          </p>
          <p>
            3.3 <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction.
          </p>

          <span className='subtitle'>4. Data Security</span>
          <p>
            We take reasonable measures to protect your personal information from unauthorized access and use. However, no data transmission over the internet or electronic storage is entirely secure, so we cannot guarantee absolute security.
          </p>

          <span className='subtitle'>5. Your Choices</span>
          <p>
            You can exercise the following rights with respect to your information:
          </p>
          <p>
            5.1 <strong>Access and Correction:</strong> You can request access to your personal information and make corrections if necessary.
          </p>
          <p>
            5.2 <strong>Opt-Out:</strong> You can opt-out of receiving promotional emails from us by following the unsubscribe instructions provided in the emails.
          </p>

          <span className='subtitle'>6. Changes to this Privacy Policy</span>
          <p>
            We may update this Privacy Policy from time to time to reflect changes in our practices or for legal reasons. We will post the updated Privacy Policy on the Website, and the revised date will be indicated at the top of the policy.
          </p>

          <span className='subtitle'>7. Contact Us</span>
          <p>
            If you have any questions, concerns, or requests related to this Privacy Policy or your personal information, please contact us at info@datastudyguide.com.
          </p>

          <div className="connectButton">
            <button className="connectbutton">
              <Link to="/" className="link">Back to Home</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;