import React from 'react';
import "./HeroSection.scss";
import hero_section_image from "../../assets/hero_section_image.svg";
import { Link } from "react-router-dom";

function HeroSection() {
  return (
    <div className='heroSection'>
      <div className='container'>
            <h1 className='main-hero-section-maintitle'>
                Upgrade your Knowledge with our <span className='data-mastery'>Data Mastery</span> courses
            </h1>
        <div className='hero-section-bottom-part'>
            <div className='left-half'>
                <div className='subtitle'>Navigate the Data Landscape with Ease and Precision</div>
                <div className='find-courses-button'> 
                   <Link to="/courses" className='link'>Find Courses</Link>
                </div>
                <div className='hero-section-points'>
                    <div className='points-line'>
                        <div className='point1'>Never Stop Learning</div>
                        <div className='point2'>Data Science</div>
                        <div className='point3'>Data Science</div>
                    </div>
                    <div className='points-line'>
                        <div className='point1'>LLMs</div>
                        <div className='point2'>Python</div>
                        <div className='point3'>Generative AI</div>
                    </div>
                </div>
            </div>
            
            <div className='right-half'>
                <img src={hero_section_image} alt="" className='hero-image'/>
            </div>    
            </div>
      </div>
    </div>
  )
}

export default HeroSection
