import React, { createContext, useState } from 'react';

const CourseContext = createContext({
    selectedLesson: null,
    setSelectedLesson: () => {},
    completedLessons: new Set(),  // Tracks IDs of completed lessons
    completeLesson: (lessonId) => {}  // Method to mark a lesson as completed
});

export const CourseContextProvider = ({ children }) => {
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [completedLessons, setCompletedLessons] = useState(new Set());

    const completeLesson = (lessonId) => {
        setCompletedLessons(prevCompletedLessons => {
            const newCompletedLessons = new Set(prevCompletedLessons);
            newCompletedLessons.add(lessonId);
            return newCompletedLessons;
        });
    };

    return (
        <CourseContext.Provider value={{
            selectedLesson,
            setSelectedLesson,
            completedLessons,
            completeLesson
        }}>
            {children}
        </CourseContext.Provider>
    );
};

export default CourseContext;
