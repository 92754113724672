// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

// the auth-provider component
export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true); // Add a loading state

    useEffect(() => {
        const validateTokenAndGetUser = async () => {
            // console.log('Starting user validation process...');
            const token = localStorage.getItem('accessToken');
            if (!token) {
                // console.log('No token found in localStorage. Ending validation process.');
                setLoading(false);
                return;
            }
    
            // console.log(`Token found: ${token}. Proceeding to validate token and fetch user details...`);
            try {    
                const response = await axios.get(`https://dsgbackend.azurewebsites.net/api/me`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                // console.log('User details successfully fetched from backend:', response.data);
                setCurrentUser(response.data);
            } catch (error) {
                // console.error('Error during token validation or fetching user details:', error);
                localStorage.removeItem('accessToken');
                // console.log('Invalid token removed from localStorage.');
            } finally {
                setLoading(false);
                // console.log('User validation process completed.');
            }
        };
    
        validateTokenAndGetUser();
    }, []);
    
    
    const login = async (email, password) => {
        // console.log('Attempting to log in...');
        try {
            
            const response = await axios.post(`https://dsgbackend.azurewebsites.net/api/auth/login`, { email, password });
            // console.log('Login successful:', response.data);
            localStorage.setItem('accessToken', response.data.user.accessToken);
            setCurrentUser(response.data.user);
        } catch (error) {
            // console.error('Login failed:', error);
            throw error;
        }
    };

    const logout = async () => {
        // console.log('Attempting to log out...');
        try {
            await axios.post(`https://dsgbackend.azurewebsites.net/api/auth/logout`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            // console.log('Logout successful');
            localStorage.removeItem('accessToken');
            setCurrentUser(null);
        } catch (error) {
            // console.error('Logout failed:', error);
        }
    };

    const register = async (email, password) => {
        // console.log('Attempting to register...');
        try {
            const response = await axios.post(`https://dsgbackend.azurewebsites.net/api/auth/register`, { email, password });
            // console.log('Registration successful:', response.data);
            localStorage.setItem('accessToken', response.data.user.accessToken);
            setCurrentUser(response.data.user);
        } catch (error) {
            // console.error('Registration failed:', error);
            throw error;
        }
    };

    const forgotPassword = async (email) => {
        // console.log('Attempting to initiate forgot password process...');
        try {
            await axios.post(`https://dsgbackend.azurewebsites.net/api/auth/forgotpassword`, { email });
            // console.log('Forgot password request successful');
        } catch (error) {
            // console.error('Forgot password request failed:', error);
            throw error;
        }
    };

    const resetPassword = async (email, otp, newPassword, confirmPassword) => {
        // console.log('Attempting to reset password...');
        try {
            const response = await axios.post(`https://dsgbackend.azurewebsites.net/api/auth/resetpassword`, {
                email, otp, newPassword, confirmPassword // Include confirmPassword in the payload
            });
            // console.log('Password reset successful');
            // Add any additional logic needed after a successful password reset
        } catch (error) {
            // console.error('Password reset failed:', error);
            throw error; // Consider handling errors more gracefully
        }
    };

    const googleSignIn = async (token) => {
        // console.log('Attempting to sign in with Google...');
        try {
            const response = await axios.post(`https://dsgbackend.azurewebsites.net/api/auth/googleSignIn`, { token });
            // console.log('Google sign-in successful:', response.data);
            localStorage.setItem('accessToken', response.data.user.accessToken);
            setCurrentUser(response.data.user);
        } catch (error) {
            // console.error('Google sign-in failed:', error);
            throw error;
        }
    };

    const value = {
        currentUser,
        loading,
        login,
        logout,
        register,
        forgotPassword,
        resetPassword,
        googleSignIn,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
