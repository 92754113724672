import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "./CourseDetails.scss";
import CourseHeroSection from '../../components/courseHeroSection/CourseHeroSection';
import Whatyoulearn from '../../components/whatyoulllearn/Whatyoulearn';
import Companies from '../../components/companies/Companies';
import CTA from '../../components/CTA/CTA.jsx';
import CourseCurriculum from '../../components/courseCurriculum/CourseCurriculum';
import WhyDSG from '../../components/whyDSG/WhyDSG';
import Faq from '../../components/faq/Faq';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';

function CourseDetails() {


  const { courseId } = useParams();
  // console.log('Component loaded with courseId:', courseId);

  const { currentUser } = useAuth();
  // console.log('Current user:', currentUser);

  const [course, setCourse] = useState(null);
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [isInsideDashboard, setIsInsideDashboard] = useState(window.location.pathname.includes("dashboard"));
  // console.log('Is inside Dashboard:', isInsideDashboard);

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // console.log('Fetching course details for ID:', courseId);
    const fetchCourseDetails = async () => {
      try {
        const response = await axios.get(`https://dsgbackend.azurewebsites.net/api/courses/courses/${courseId}`);
        // console.log('Course details response:', response);
        if (response.status === 200) {
          setCourse(response.data);
          // console.log('Course details set:', response.data);
        } else {
          // console.error('Error fetching course details:', response);
        }
      } catch (error) {
        // console.error('Failed to fetch course details:', error);
      }
    };

    fetchCourseDetails();
  }, [courseId]);

  useEffect(() => {
    // console.log('Attempting to fetch user ID...');
    const fetchUserId = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        // console.log('Token found:', token);
        if (!token) {
          // console.log('No token found');
          return;
        }

        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const response = await axios.get(`https://dsgbackend.azurewebsites.net/api/me`, config);
        //console.log('User ID fetched:', response.data._id);
        setUserId(response.data._id);
      } catch (error) {
        // console.error('Failed to fetch user ID:', error);
      }
    };

    fetchUserId();
  }, [currentUser]);

  useEffect(() => {
    // console.log("hey bro started out with validation")
    const checkUserRegistration = async () => {
      if (!userId || !courseId) {
        // console.log('Missing userId or courseId for validation');
        return;
      }
  
      // console.log(`Checking registration status for userId: ${userId} and courseId: ${courseId}`);
      
      try {
        const validationResponse = await axios.post(`https://dsgbackend.azurewebsites.net/api/validate-user-registration`, {
          userId: userId,
          courseId: courseId,
        });
        // console.log("Validation response received:", validationResponse.data.message);
        
        // Update based on the simplified "Yes" or "No" response
        setIsUserRegistered(validationResponse.data.message === "Yes");
      } catch (error) {
        // console.error('Error checking user registration:', error);
        setIsUserRegistered(false);
      }
    };
  
    if (userId) {
      checkUserRegistration();
    }
  }, [userId, courseId]);
  

  return (
    <div className='courseDetails'>
      {course ? (
        <>
          <CourseHeroSection 
            title={course.title}
            description={course.description}
            imageUrl={course.mainImage}
            isInsideDashboard={isInsideDashboard}
            courseId={courseId}
            userIsLoggedIn={!!currentUser} // Use currentUser to set userIsLoggedIn prop
            isUserRegistered={isUserRegistered}
            price={course.price}
          />
          <Whatyoulearn />
          <Companies />
          <CourseCurriculum modules={course.curriculum} />
          <WhyDSG />
          <Faq faqs={course.faqs} />
          <CTA />
        </>
      ) : <div>Loading...</div>}
    </div>
  );
}

export default CourseDetails;
