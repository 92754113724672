import React from 'react';
import { Link } from 'react-router-dom';
import './termsandconditions.scss';

function TermsAndConditions() {
  return (
    <div className="terms"> {/* Apply the "terms" class */}
      <div className="container"> {/* Apply the "container" class */}
        
        <div className="content">
          <span className='title'>Terms & Conditions</span>

          <span className='subtitle'>Using the website</span>
          <p>
            By using our Data StudyGuide Website, you are deemed to have accepted the following terms and conditions. If you do not want to be legally bound by these terms and conditions, please do not access or use the Data StudyGuide Website. We reserve the right to change these terms and conditions at any time, and you are advised to review these terms regularly to ensure you are aware of any changes. Your continued use of the Data StudyGuide Website after such changes are posted will be deemed agreement on your part to these terms and conditions as amended.
          </p>

          {/* <h3>Intellectual property</h3> */}
          <p>
            <strong>2.1 Copyright and all intellectual property rights</strong><br />
            Copyright and all intellectual property rights in the content of the Data StudyGuide website are vested in Data Study Guide and reserved, unless indicated otherwise. The content of the Website belongs to Codible Ventures LLP unless indicated otherwise. You may use the content of the Data StudyGuide Website subject to the following conditions:
            <ul>
              <li>(a) It is used for information purposes only</li>
              <li>(b) It is used only for your own personal, non-commercial use</li>
              <li>(c) Any copies or downloads of any content from the Data Study Guide Website must include a notice that copyright in the relevant material is owned by Data Study Guide.</li>
            </ul>
          </p>
          <p>
            <strong>2.2 Except as expressly provided above</strong><br />
            Except as expressly provided above, nothing contained on this Data Study Guide Website should be construed as conferring any license or right to use any trademark or copyright of Data Study Guide or any third party.
          </p>

          <div className="connectButton">
            <button className="connectButtonButton">
              <Link to="/" className="connectButtonLink">Back to Home</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;