import React, { useState, useMemo, useEffect } from 'react';
import Navbar from '../../components/dashboard/navbar/Navbar';
import Sidebar from '../../components/dashboard/sidebar/Sidebar';
import { Outlet, useLocation } from 'react-router-dom';
import "./Userdashboard.scss";
import CourseContentSidebar from '../../components/dashboard/coursecontentsidebar/CourseContentSidebar';
import courseContext from '../../utils/courseContext.js';

function Userdashboard() {
  const location = useLocation();
  const isCourseContentPage = location.pathname.startsWith('/dashboard/page');

  // Initialize the sidebar visibility based on the window width
  const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth > 1080);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const value = useMemo(() => ({ selectedLesson, setSelectedLesson }), [selectedLesson]);

  const toggleSidebar = () => {
    setIsSidebarVisible(prev => !prev);
  };

  // Handle window resize to update sidebar visibility based on the width
  useEffect(() => {
    const handleResize = () => {
      // Automatically hide the sidebar when the window width is <= 1080px
      if (window.innerWidth <= 1080) {
        setIsSidebarVisible(false);
      } else {
        setIsSidebarVisible(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Print current sidebar visibility in console
  useEffect(() => {
    console.log('Sidebar is now', isSidebarVisible ? 'visible' : 'hidden');
  }, [isSidebarVisible]);

  const sidebarClass = `sidebar ${isSidebarVisible ? 'visible' : 'hidden'}`;

  return (
    <courseContext.Provider value={value}>
      <div className='user-dashboard'>
        <Navbar toggleSidebar={toggleSidebar}  className="NAVBAR"/>
        <div className='dashboard-layout'>
          <div className={sidebarClass}>
            {isCourseContentPage ? 
                <CourseContentSidebar isVisible={isSidebarVisible} toggleSidebar={toggleSidebar} /> : 
                <Sidebar isVisible={isSidebarVisible}  toggleSidebar={toggleSidebar}/>}
          </div>
          <div className="dashboard-content">
            <Outlet />
          </div>
        </div>
      </div>
    </courseContext.Provider>
  );
}

export default Userdashboard;
