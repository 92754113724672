import React from 'react';
import "./FailedPayment.scss"; // Ensure you create a corresponding SCSS file for styling
import { Link } from 'react-router-dom';

function CancelledPayment() {
  return (
    <div className='cancelled-page'>
      <div className='container'>
         <h2>Payment Cancelled</h2>
         <span>Your payment was not completed. Your order has not been confirmed.</span>
         {/* <button>
           <Link to="/dashboard/purchase" className='link'>View Orders</Link>
         </button> */}
         <button>
           <Link to="/dashboard/courses" className='link'>Continue Browsing Courses</Link>
         </button>
      </div>
    </div>
  );
}

export default CancelledPayment;
