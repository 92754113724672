import React, { useEffect } from 'react';
import './Success.scss';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext.js';

function Success() {
  const { currentUser } = useAuth();

  // useEffect(() => {
  //   const sendSuccessEmail = async () => {
  //     if (!currentUser || !currentUser.email) {
  //       console.error('No current user or user email found');
  //       return;
  //     }

  //     // Define the course details, ideally fetched or passed from the previous component/state
  //     const courseDetails = {
  //       courseName: 'Data Science',
  //       amountPaid: '$8000',
  //       paymentDate: '2024-03-24',
  //       additionalInfo: 'Starts on April 5th, 2024, live classes on Saturdays and Sundays.',
  //     };

  //     try {
  //       // Ensure this URL matches your actual API endpoint
  //       await axios.post('https://dsgbackend.azurewebsites.net/api/sendPaymentSuccessEmail', {
  //         email: currentUser.email,
  //         courseDetails,
  //       });
  //       console.log('Success email sent.');
  //     } catch (error) {
  //       console.error('Failed to send success email:', error);
  //     }
  //   };

  //   if (currentUser && currentUser.email) {
  //     sendSuccessEmail();
  //   }
  // }, [currentUser]);

  // useEffect(() => {
  //   // This second useEffect handles any other logic you might want to execute on the success page,
  //   // such as course registration through session storage data.
  //   const storedData = sessionStorage.getItem('registrationData');
  //   const registrationData = storedData ? JSON.parse(storedData) : {};
  //   sessionStorage.removeItem('registrationData');

  //   const registerCourse = async () => {
  //     if (Object.keys(registrationData).length) {
  //       try {
  //         const response = await axios.post(`${baseURL}//api/course/course-registration`, registrationData);
  //         // console.log('Course registration successful:', response.data);
  //       } catch (error) {
  //         // console.error('Failed to register course:', error);
  //       }
  //     }
  //   };

  //   registerCourse();
  // }, []);

  return (
    <div className='success-page'>
      <div className='container'>
        <h2>Payment Successful</h2>
        <span>Thank you for your payment. Your order has been confirmed.</span>
        <button>
          <Link to="/dashboard/purchase" className='link'>Go to the Course</Link>
        </button>
      </div>
    </div>
  );
}

export default Success;
