import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import "./chat_with_us.scss";
import whatsappImage from "../../assets/whatsapp.svg"; // Ensure this path is correct

function Chat_with_us() {
  // The path you want to navigate to when the chat icon is clicked
  const internalLinkPath = "https://wa.me/16692149864";

  return (
    <Link to={internalLinkPath} target="_blank" rel="noopener noreferrer" className='chat_with_us'>
      <img src={whatsappImage} alt="Chat with us" className="whatsapp_image"/>
      <span className='chat_with_us_text'>Chat with us</span>
    </Link>
  );
}

export default Chat_with_us;