import React, { useState, useEffect } from 'react';
import "./courses.scss";
import CourseListingCard from '../../components/courseListingCard/CourseListingCard.jsx';

const BASE_URL = 'https://dsgbackend.azurewebsites.net';

function Courses() {
  const [searchQuery, setSearchQuery] = useState('');
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCourses = async () => {
      // console.log('Fetching courses from API...');
      try {
        const response = await fetch(`${BASE_URL}/api/courses/getallcourses`);
        if (response.ok) {
          const data = await response.json();
          // console.log('Fetched courses:', data);
          setCourses(data);
          setFilteredCourses(data);
        } else {
          throw new Error('Response not ok');
        }
      } catch (error) {
        // console.error('Failed to fetch courses:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCourses();
  }, []);

  useEffect(() => {
    // console.log('Filtering courses based on search query:', searchQuery);
    const result = courses.filter(course =>
      (course.title?.toLowerCase() || '').includes(searchQuery.trim().toLowerCase())
    );
    // console.log('Filtered courses:', result);
    setFilteredCourses(result);
  }, [searchQuery, courses]);
  

  return (
    <div className='courselistingpage'>
      <div className='container'>
        <div className='search-container'>
          <div className='search-container-left-side'>
            <h2>Explore Data Study Guide's top Courses</h2>
            <p>Unlock insights and elevate your skills with our curated selection of top-notch data courses.</p>
          </div>
          <div className='search-container-right-side'>
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <div className='courseCards'>
          {isLoading ? (
            Array.from({ length: 5 }).map((_, index) => (
              <CourseListingCard key={index} isLoading={true} />
            ))
          ) : (
            filteredCourses.map((course, index) => (
              <CourseListingCard
                key={course._id || index}
                courseId={course._id}
                courseImage={course.mainImage} 
                courseTitle={course.title}
                isInsideDashboard={false}
                isLoading={false}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default Courses;
