import React from 'react';
import { Link } from 'react-router-dom';
import "./aboutUs.scss"; // Import your stylesheet for AboutUs

function AboutUs() {
  return (
    <div className="about">
      <div className="container">
        <div className="content">
          <span className='title'>About Us</span>
          <p>
            Welcome to Data Study Guide, your portal to mastering data science. Our mission is to equip you with the knowledge and skills needed to thrive in the data-driven world.
          </p>

          <h2>What Sets Us Apart:</h2>
          <ul>
            <li><strong>Comprehensive Curriculum:</strong> Our data science bootcamp covers everything from basics to advanced topics, suitable for beginners and experienced learners alike.</li>
            <li><strong>Expert Instructors:</strong> Learn from experienced data scientists and industry experts who provide real-world insights.</li>
            <li><strong>Hands-On Learning:</strong> Our emphasis on practical projects ensures you gain valuable experience and create a strong portfolio.</li>
            <li><strong>Community and Networking:</strong> Join our vibrant community to connect with peers and build professional relationships.</li>
            <li><strong>Flexible Learning:</strong> We offer full-time and part-time programs to suit your schedule and preferences.</li>
            <li><strong>Career Support:</strong> Access career services to help you launch or advance your data science career.</li>
          </ul>

          <p>
            Thank you for choosing Data Study Guide. Let's embark on your data science journey together!
          </p>
        </div>

        <div className="connectButton">
          <button className="connectbutton">
            <Link to="/" className="link">Back to Home</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;