import React from 'react';
import "./CourseHeroSection.scss";
import { Link } from 'react-router-dom';

function CourseHeroSection({ title, description, imageUrl, isInsideDashboard,courseId,userIsLoggedIn,isUserRegistered,price }) {
// Determine the link based on whether the user is inside the dashboard
  // const enrollLink = isInsideDashboard ? `/course-registration/${courseId}` : `/login`;  
  const enrollLink = userIsLoggedIn ? `/course-registration/${courseId}` : `/login`;
  // const viewCourseLink = `/course-content/${courseId}`;
    // const viewCourseLink = `/dashboard/course-content/:{courseId}`;
    
    const viewCourseLink = `/dashboard/page/${courseId}`;

  return (
    <div className='courseherosection'>
      <div className='container'>
        <div className='herosection-leftside'>
          <div className='course-content'>
            <h2 className='maintitle'>{title}</h2> {/* Updated to use title prop */}
            <p>{description}</p> {/* Updated to use description prop as paragraph */}
            {/* {!isUserRegistered && (
              <Link to={enrollLink} className='enroll-now-button'>
                  <button className='link'>Enroll Now at ${price}</button>
              </Link>
            )} */}
            {/* {isUserRegistered && ( // Conditionally render "View Course" button if the user is registered
              <Link to={viewCourseLink} className='enroll-now-button'>
                <button className='link'>View Course</button>
              </Link>
            )} */}
          </div>
          {/* Additional section unchanged */}
          <div className='hero-section-bottom-part'>
            <div className='points-line'>
              <div className='point1'>Never Stop Learning</div>
              <div className='point2'>Data Science</div>
              <div className='point3'>Data Science</div>
            </div>
            <div className='points-line'>
              <div className='point1'>LLMs</div>
              <div className='point2'>Python</div>
              <div className='point3'>Generative AI</div>
            </div>
          </div>
        </div>
        <div className='herosection-rightside'>
          <img className='course-image' src={imageUrl} alt="Course Image"/> {/* Updated to use imageUrl prop */}
        </div>
      </div>
    </div>
  )
}

export default CourseHeroSection;
