import React from 'react'
import "./footer.scss";
import { Link } from 'react-router-dom';
import FOOTER_LOGO from "../../assets/footer logo.svg";
import FACEBOOK from "../../assets/facebook.svg";
import TWITTER from "../../assets/twitter.svg";
import INSTAGRAM from "../../assets/instagram.svg";

const INSTAGRAM_LINK = "https://www.instagram.com/datastudyguide?igsh=c3d5cDMwemlxejM4"
const FACEBOOK_LINK = ""
const TWITTER_LINK  = ""
function Footer() {
  return (
    <div className='footer'>
      <div className='container'>
        <div className='footer-main-links'>
          <div className='footer-main-links-left'>
            <div className='footer-logo-container'>
              <Link to="/" className='link'>
                <img src={FOOTER_LOGO} alt="Footer Logo" className='footer-logo'/>
              </Link>   
            </div>
            <div className='social-links'>
              <a href={FACEBOOK_LINK} target="_blank" rel="noopener noreferrer">
                <img src={FACEBOOK} alt="Facebook" className='social-links-image'/>
              </a>
              <a href={TWITTER_LINK} target="_blank" rel="noopener noreferrer">
                <img src={TWITTER} alt="Twitter" className='social-links-image'/>
              </a>
              <a href={INSTAGRAM_LINK} target="_blank" rel="noopener noreferrer">
                <img src={INSTAGRAM} alt="Instagram" className='social-links-image'/>
              </a>
            </div>
          </div>
          <div className='footer-main-links-right'>
           <Link className='left-points'>Home</Link>
            <Link className='left-points'>Courses</Link>
            <Link className='left-points' to="/about-us">About Us</Link>
            <Link className='left-points' to="/contact-us">Contact Us</Link>
          </div>
          <div className='social-links-for-660px'>
            <a href={FACEBOOK_LINK} target="_blank" rel="noopener noreferrer">
                <img src={FACEBOOK} alt="Facebook" className='social-links-image'/>
              </a>
              <a href={TWITTER_LINK} target="_blank" rel="noopener noreferrer">
                <img src={TWITTER} alt="Twitter" className='social-links-image'/>
              </a>
              <a href={INSTAGRAM_LINK} target="_blank" rel="noopener noreferrer">
                <img src={INSTAGRAM} alt="Instagram" className='social-links-image'/>
              </a>
          </div>
        </div>
        <div className='footer-bottom-links'>
          <div className='footer-bottom-links-left'>
            <span className='footer-bottom-links-left-text'>© Data Study Guide. All rights reserved.</span>
          </div>
          <div className='footer-bottom-links-right'>
            <Link className='right-points' to="/privacy-policy">Privacy Policy</Link>
            <Link className='right-points' to="/terms-and-conditions">Terms & Conditions</Link>
            <Link className='right-points' to="/refund-policy">Refund Policy</Link>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Footer
