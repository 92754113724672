import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import "./ForgotPassword.scss";
import { useAuth } from '../../context/AuthContext'; // Adjust this path to where your AuthContext is
import JOIN_NOW from "../../assets/LOGIN1.svg";


function ForgotPassword() {
  const { forgotPassword } = useAuth(); // Destructure forgotPassword from useAuth hook
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
        setEmailError("Email is required.");
        return;
    }

    setLoading(true);
    try {
        await forgotPassword(email);
        setSuccessMessage("A reset password link has been sent to your email.");
        navigate('/resetpassword');
    } catch (error) {
        setErrorMessage("Failed to send reset password link. Please try again.");
    } finally {
        setLoading(false);
    }
};

  return (
    <div className='forgotpassword'>
      <div className='container'>
        <div className='forgotpassword-left-side'>
          <img className='image' src={JOIN_NOW} alt=""/>
        </div>
        <div className='forgotpassword-form'>
          <div className='forgotpassword-top-section'>
            <div className='forgotpassword-form-header'>
                <h2>Reset password</h2>
                <span>
                  Enter your email address and we'll send you a link to reset your password.
                </span>
            </div>
            <div className='form' onSubmit={handleSubmit}>
              <TextField
                    error={!!emailError}
                    helperText={emailError || ''}
                    id="filled-email-input"
                    label="Email"
                    type="email"
                    autoComplete="email"
                    variant="filled"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} // Set email state
                />
            <button type="submit" className='reset-button' onClick={handleSubmit} disabled={loading}>
                {loading ? 'Loading...' : 'Reset Password'}
            </button>
            </div>
          </div>
          <span className="login-subtitle">
                By joining, you agree to the Data Study Guide Terms & Conditions and to occasionally receive emails from us. Please read our Privacy Policy to learn how we use your personal data.
          </span>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
