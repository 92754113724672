import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TextField, Button, Box } from '@mui/material';
import "./ResetPassword.scss"
import JOIN_NOW from "../../assets/LOGIN1.svg"
import { useAuth } from '../../context/AuthContext';

const OTPInput = ({ length, value, onChange }) => {
  const handleChange = (e, index) => {
    const val = e.target.value;
    // Ensure only numeric values are accepted
    if (!/^\d*$/.test(val)) return;

    const newOtp = [...value.split(''), ...Array(length).fill('')].slice(0, length);
    newOtp[index] = val;
    onChange(newOtp.join(''));

    // Move focus forward on input
    if (val && index < length - 1) {
      e.target.form[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    // Move focus backward on backspace if current field is empty
    if (e.key === 'Backspace' && !value[index] && index > 0) {
      e.target.form[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, length).split('');
    if (pasteData.length === length && pasteData.every(char => /^\d$/.test(char))) {
      onChange(pasteData.join(''));
      // Focus the last input after pasting
      const form = e.target.form;
      form[length - 1].focus();
    }
  };

  const handleFocus = (e) => e.target.select();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }} component="form" onPaste={handlePaste}>
      {Array.from({ length }, (_, index) => (
        <TextField
          key={index}
          variant="filled"
          inputProps={{ maxLength: 1 }}
          sx={{
            input: { textAlign: 'center', color: 'white' },
            '& .MuiFilledInput-input': { padding: '10px' },
            '& .MuiFilledInput-underline:after': { borderBottomColor: 'blue' },
            '& .MuiFilledInput-root': {
              backgroundColor: 'rgba(255, 255, 255, 0.09)',
              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.13)' },
              '&.Mui-focused': { backgroundColor: 'rgba(255, 255, 255, 0.12)' },
            },
          }}
          value={value[index] || ''}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onFocus={handleFocus}
        />
      ))}
    </Box>
  );
};


function ResetPassword() {
  const { resetPassword, forgotPassword } = useAuth();
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [resendLoading, setResendLoading] = useState(false); 
  const [resetLoading, setResetLoading] = useState(false); 

  // const [emailError, setEmailError] = useState(false);
  // const location = useLocation(); // This hook gives us access to the location object
  // const emailFromState = location.state?.email; // Retrieve the email passed from ForgotPassword

  
  console.log("ResetPassword component rendered");

  const handleResetPassword = async () => {
    console.log("Reset password button clicked");

    // Log the details being sent for password reset, including confirmPassword
    console.log("Details sent for password reset:", {
        email: email,
        otp: otp,
        newPassword: password,
        confirmPassword: confirmPassword // Including confirmPassword in the log for clarity
    });

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      console.log("Passwords do not match");
      return;
    }

    console.log("Email to send:", email);
    // Additional validation can be added here, such as email format verification
    if (!email) {
      setError("Please enter a valid email address.");
      console.log("Email is not provided or invalid");
      return;
    }
  
    console.log("Sending reset password request with confirmPassword...");

    try {
      // Now including confirmPassword in the payload sent to the backend
      await resetPassword(email, otp, password, confirmPassword);
      navigate('/login', { state: { resetSuccess: true } });
      console.log("Password reset request sent successfully");
    } catch (error) {
      console.error('Reset password request failed:', error);
      setError(error.response?.data?.message || "Failed to reset password. Please try again.");
    }
}


  
  const handleResendOTP = async () => {
    // Check if email input is empty or only contains white spaces
    if (!email.trim()) {
      setError("Please enter your email to receive the OTP.");
      console.log("No email provided. Prompting user for email input.");
      return; // Exit the function if no email is provided
    }

    try {
      setResendLoading(true); // Indicate the start of the resend operation
      await forgotPassword(email); // Use the provided email to resend the OTP
      console.log("OTP resent successfully.");
      // Optionally, you can clear the error state if previously set
      setError(''); // Clear any existing error messages
      // Optionally, display a success message that OTP has been resent
    } catch (error) {
      console.error("Error resending OTP:", error);
      setError("Please enter your email to receive the OTP.");
    } finally {
      setResendLoading(false); // Reset the loading state
    }
  };


  return (
    <div className='resetpassword'>
      <div className='container'>
        <div className='resetpassword-left-side'>
          <img className='image' src={JOIN_NOW} alt=""/>
        </div>
        <div className='resetpassword-form'>
            <div className='resetpassword-form-top-section'>
              <div className='forgotpassword-form-header'>
                  <h2>Reset password</h2>
              </div>
              <div className='form'>
              <OTPInput length={6} value={otp} onChange={setOtp} />
              <TextField
                label="Email"
                type="email"
                variant="filled"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            <TextField
              label="New Password"
              type="password"
              variant="filled"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              label="Confirm Password"
              type="password"
              variant="filled"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            /> 
             {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
              <button className='resetbutton' variant="contained" color="primary" fullWidth style={{ marginTop: '20px' }} onClick={handleResetPassword}>
                Reset Password
              </button>
              </div>
            </div>
            <div className='resend-otp'>
                <Button className="resend-otp-button" disabled={resendLoading} onClick={handleResendOTP}>
                  Resend OTP
                </Button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
