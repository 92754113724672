import React, { useState, useEffect, useRef } from 'react';
import "./Navbar.scss";
import DataStudyGuide from "../../../assets/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { useAuth } from '../../../context/AuthContext';
import MenuIcon from '@mui/icons-material/Menu';

function Navbar({ toggleSidebar }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null); // Ref for the dropdown
  const { logout } = useAuth();
  const navigate = useNavigate();


   const handleLogout = async () => {
        try {
            // console.log('Attempting to log out...');
            await logout(); // Use the logout method from AuthContext

            // console.log('Logout successful');
            navigate('/'); // Navigate to the home page or login page as needed
        } catch (error) {
            // console.error('Logout failed:', error);
            // Optionally handle any logout errors here, like showing a message to the user
        }
    };


  const toggleDropdown = () => setShowDropdown(!showDropdown);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside of the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false); // Close the dropdown
      }
    };

    // Add when the component is mounted
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up to avoid memory leaks
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Empty array means this effect runs once on mount

  return (
    <div className='dashboard-navbar'>
      <div className='container'>
        <div className='logo'>
           <MenuIcon className='menu-icon' onClick={toggleSidebar}/>
          <Link to="/"> <img className='logoimage' src={DataStudyGuide} alt=""/> </Link>
        </div>
        <div className='right-navbar'>
          <div className='navlinks'>
            <ul>
              <li>
                <Link to="/dashboard/courses" className="link">Courses</Link>
              </li>
              {/* <li>
                <Link to="/dashboard/purchase" className="link">Purchases</Link>
              </li> */}
              {/* <li>
                <Link to="/dashboard/settings" className="link">Join Discord</Link>
              </li> */}
            </ul>
          </div>
          <div className='account-image' ref={dropdownRef}>
            <AccountCircleRoundedIcon className='account' onClick={toggleDropdown}/>
            {showDropdown && (
              <div className='dropdown-menu'>
                {/* <Link to="/dashboard/courses" className="link">Courses</Link>
                <Link to="/dashboard/purchase" className="link">Purchases</Link> */}
                <Link to="/dashboard/settings" className="link">Settings</Link>
                <Link onClick={handleLogout} className="link">Logout</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
