// Faq.js
import React from 'react';
import "./faq.scss";
import FaqCard from '../faqCard/FaqCard.jsx'; // Import FaqCard component

function Faq({ faqs }) { // Accept FAQs data as props
  return (
    <div className='faq'>
       <div className='container'>
            <h2>Frequently Asked Questions.</h2>
            <div className='faqcards-part'>
              {faqs.map((faq, index) => (
                <FaqCard key={index} faq={faq} /> // Render FaqCard for each FAQ
              ))}
            </div>
      </div>
    </div>
  )
}

export default Faq;
