import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./Purchase.scss";
import PurchasedCourseCard from "../../../components/purchasedCourseCard/PurchasedCourseCard.jsx";
import axios from 'axios';
function Purchase() {
  const [isLoading, setIsLoading] = useState(true);
  const [registrations, setRegistrations] = useState([]); // State to hold registrations

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        if (!token) {
          // console.log('No token found');
          return;
        }

        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const response = await axios.get(`https://dsgbackend.azurewebsites.net/api/me`, config);

        if (response.data) {
          // console.log('User ID fetched successfully', response.data);
          fetchUserRegistrations(response.data._id);
        }
      } catch (error) {
        // console.error('Failed to fetch user ID:', error);
      }
    };

    const fetchUserRegistrations = async (userId) => {
      // console.log('Starting to fetch user registrations for userID:', userId);
    
      try {
        // console.log('Making GET request to fetch registrations');
        const response = await axios.get(`https://dsgbackend.azurewebsites.net/api/user-registrations/${userId}`);
        
        // console.log('GET request successful, response data:', response.data);
        setRegistrations(response.data); // Store the fetched registrations
        
        setIsLoading(false);
        // console.log('Set isLoading to false, registrations fetched');
      } catch (error) {
        // console.error('Failed to fetch user registrations:', error);
        setIsLoading(false);
        // console.log('Set isLoading to false due to error');
      }
    };
    fetchUserId();    
  }, []);



  return (
    <div className='purchases'>
      <div className='container'>
        <div className='purchase-title-button'>
          <span className='maintitle'>My Purchases</span>
          <button className='course-button'>
            <Link to="/dashboard/courses" className='link'>Courses</Link>
          </button>
        </div>
        <div className='purchased-courses'>
          {isLoading ? (
            <div className={`purchasedCourseCard skeleton`}>
              {/* Skeleton or loading state shown here */}
            </div>
          ) : registrations.length === 0 ? (
            <div className="no-courses-message">
              It looks like you haven't enrolled in any courses yet. Start exploring our diverse course offerings now!
              <Link to="/dashboard/courses" className='link browse-link'>Explore Courses</Link>        
            </div>
          ) : (
            registrations.map((course, index) => (
              <PurchasedCourseCard
                key={course.id || index} // Using course ID if available, otherwise index
                courseId={course.courseId} // Assuming the course structure has these fields
              />
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default Purchase;
