import React, { useState, useContext, useEffect } from 'react';
import "./CourseContentSidebar.scss";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CourseContext from '../../../utils/courseContext';
import MenuIcon from '@mui/icons-material/Menu';
import DataStudyGuide from "../../../assets/logo.svg";
import { Link } from "react-router-dom";

function CourseContentSidebar({ isVisible, toggleSidebar }) {
    const [activeModules, setActiveModules] = useState({});
    const [courseModules, setCourseModules] = useState([]);
    const { selectedLesson, setSelectedLesson, completedLessons } = useContext(CourseContext);
    const { pageId } = useParams();

    // Define a default module and lesson in case of fetch failure
    const defaultModule = [{
        _id: 'defaultModuleId',
        moduleNumber: 'Default Module',
        lessons: [{
            _id: 'defaultLessonId',
            lessonName: 'Default Lesson',
            video: 'defaultVideo.mp4'  // Ensure you have a default video or handle this gracefully
        }]
    }];

    // Fetch course modules based on courseId
    useEffect(() => {
        const fetchCourseModules = async () => {
            try {
                const response = await axios.get(`https://dsgbackend.azurewebsites.net/api/course-content/${pageId}`);
                if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                    setCourseModules(response.data);
                } else {
                    console.error('Data fetched is not an array or is empty:', response.data);
                    setCourseModules(defaultModule);
                }
            } catch (error) {
                console.error('Error fetching course modules:', error);
                setCourseModules(defaultModule);
            }
        };

        if (pageId) {
            fetchCourseModules();
        }
    }, [pageId]);

    // Automatically expand the first module and set the first lesson as selected upon data load
    useEffect(() => {
        if (courseModules.length > 0 && courseModules[0].lessons.length > 0) {
            setSelectedLesson(courseModules[0].lessons[0]);
            setActiveModules({ [courseModules[0]._id]: true });
        }
    }, [courseModules, setSelectedLesson]);

    const toggleModule = (moduleId) => {
        setActiveModules(prevState => ({
            ...prevState,
            [moduleId]: !prevState[moduleId]
        }));
    };

    return (
        <div className={`course-sidebar ${isVisible ? 'visible' : 'hidden'}`}>
            <div className='course-content-sidebar-container'>
                <div className='logo'>
                    <MenuIcon className='menu-icon' onClick={toggleSidebar}/>
                    <Link to="/"> <img className='logoimage' src={DataStudyGuide} alt=""/> </Link>
                </div>
                {courseModules.map((module) => (
                    <div key={module._id}>
                        <button className="module-toggle" onClick={() => toggleModule(module._id)}>
                            Module {module.moduleNumber}
                            <ExpandMoreIcon className='expand-more-icon' />
                        </button>
                        {activeModules[module._id] && (
                            <div className="module-content">
                                {module.lessons.map((lesson, index) => (
                                    <div key={lesson._id} className="content-item" onClick={() => setSelectedLesson(lesson)}>
                                        <input type="checkbox" 
                                               id={`checkbox-${module.moduleNumber}-${index}`}
                                               checked={Array.isArray(completedLessons) && completedLessons.includes(lesson._id)}
                                               readOnly />
                                        <label htmlFor={`checkbox-${module.moduleNumber}-${index}`}>
                                            {lesson.lessonName}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CourseContentSidebar;
