// import React, { useState } from 'react';
import "./login.scss";
import React, { useEffect, useState } from 'react';
// import "./Login.scss";
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import JOIN_NOW from "../../assets/LOGIN1.svg"
import { useAuth } from '../../context/AuthContext';

function Login() {
    const navigate = useNavigate();
    const { googleSignIn } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
  // const [emailError, setEmailError] = useState(false);
  // const [passwordError, setPasswordError] = useState(false);
  
  //  const handleGoogleSignInClick = () => {
  //       const clientId =  "1072310299085-ltdcv83tk9a5bbd305thdf9s0vi2iphh.apps.googleusercontent.com";
  //       const redirectUri = "http://localhost:3000/dashboard/courses"; 
  //       const scope = "email profile openid";
  //       const responseType = "token"; 
  //       const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&response_type=${responseType}&prompt=consent`;
  //       window.location.href = authUrl;
  //   };

  const handleGoogleSignInClick = () => {
    const clientId = "1072310299085-ltdcv83tk9a5bbd305thdf9s0vi2iphh.apps.googleusercontent.com";
    const redirectUri = "https://www.datastudyguide.com/dashboard/courses";
    const scope = "email profile openid";
    const responseType = "token";
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&response_type=${responseType}&prompt=consent`;
      window.location.href = authUrl;
};
  
useEffect(() => {
  const hash = window.location.hash.substring(1);
  const params = new URLSearchParams(hash);
  const token = params.get('access_token');

  if (token) {
    localStorage.setItem('googleToken', token); // Storing the token for future use or processing
    // Assuming googleSignIn method updates the authentication state
    googleSignIn(token).then(() => navigate("/dashboard/courses"));
  }
}, [navigate, googleSignIn]);







// Function to be called with the token from the popup
// const handleTokenReceivedFromGoogle = async (token) => {
//     try {
//         await googleSignIn(token);
//         navigate("/dashboard/courses");
//     } catch (error) {
//         console.error('Google login failed:', error);
//         setLoginError('Google login failed. Please try again later.');
//     }
// };

// // Make the token handler accessible from the popup
// useEffect(() => {
//     window.handleTokenReceivedFromGoogle = handleTokenReceivedFromGoogle;

//     // Cleanup
//     return () => {
//         delete window.handleTokenReceivedFromGoogle;
//     };
// }, []);

  

  const handleGoogleSuccess = async (credentialResponse) => {
    try{
      await googleSignIn(credentialResponse.credential);
      navigate("/dashboard/courses")
    } catch(error){
      // console.error('Google login failed:', error);
      setLoginError('Google login failed. Please try again later.');
    }
  }
  
  const handleCredentialResponse = async (response) => {
          try {
              await googleSignIn(response.credential);
              navigate("/dashboard/courses");
          } catch (error) {
              // console.error('Google login failed:', error);
              setLoginError('Google login failed. Please try again later.');
          }
      };

      useEffect(() => {
          // Ensure the Google Accounts SDK has finished loading before initializing
          if (window.google) {
              window.google.accounts.id.initialize({
                  client_id: "1072310299085-ltdcv83tk9a5bbd305thdf9s0vi2iphh.apps.googleusercontent.com",
                  callback: handleCredentialResponse,
              });
          }
      }, []);

      // const handleGoogleSignInClick = () => {
      //     window.google.accounts.id.prompt(); // This will display the Google One Tap UI
      // };

    //   const handleGoogleSignInClick = () => {
    //     // Replace with your client ID and the redirect URI
    //     const clientId =  "1072310299085-ltdcv83tk9a5bbd305thdf9s0vi2iphh.apps.googleusercontent.com";
    //     const redirectUri = "http://localhost:3000/dashboard/courses"; 
    //     const scope = "email profile openid";
    //     const responseType = "token"; 
    //     const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&response_type=${responseType}&prompt=consent`;
    //     window.location.href = authUrl;
    // };

  // Call the useGoogleOneTapLogin hook
//   useGoogleOneTapLogin({
//     clientId: clientId,
//     onSuccess: async (credentialResponse) => {
//         try {
//             const response = await axios.post('https://dsgbackend.azurewebsites.net/api/auth/googleSignIn', {
//                 token: credentialResponse.credential,
//             });
            // console.log('Google login successful:', response.data);
//             localStorage.setItem('accessToken', response.data.user.accessToken);
//             navigate('/user');
//         } catch (error) {
//             console.error('Google login failed:', error);
//             setLoginError('Google login failed. Please try again later.');
//         }
//     },
//     onError: () => {
//         setLoginError('Google login failed. Please try again later.');
//     }
// });

  const handleGoogleError = () => {
    setLoginError('Google login failed. Please try again later.');
  };

  return (
    <div className='login'>
      <div className='container'>
        <div className='login-left-side'>
            <img className='image' src={JOIN_NOW} alt=""/>
        </div>
        <div className='login-form'>
           <div className='login-top-section'>
            <div className='login-form-header'>
                <h2>Sign in to your Account</h2>
                <span>
                    Don't have an account? <Link to="/register" className="link">Join here</Link>
                </span>
            </div>
            <div className='login-buttons'>
                      <Link to="/continue-with-email-password" className='email-password'>
                          <button className='link'>Continue with Email</button>
                    </Link>
                    <div className="login-with-google">
                      <GoogleLogin
                        onSuccess={handleGoogleSuccess}
                        onError={handleGoogleError}
                        render={(renderProps) => (
                            <button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            className="custom-google-login-button"
                            >
                              <img src="../../assets/google.svg" alt="Google" className="google-img" />
                              Sign in with Google
                            </button>
                        )}
                        />
                    </div> 
                    {/* <Link onClick={handleGoogleSignInClick} className='email-password'>
                          <button className='link'>Continue with Email</button>
                    </Link> */}
            </div>
           </div>
            <span className="login-subtitle">
                By joining, you agree to the Data Study Guide Terms & Conditions and to occasionally receive emails from us. Please read our Privacy Policy to learn how we use your personal data.
            </span>
        </div>
      </div>
    </div>
  )
}

export default Login
