import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import "./CourseRegisteration.scss";
import { loadStripe } from '@stripe/stripe-js';
import axios from "axios";
import { useAuth } from '../../context/AuthContext.js';

// const stripePromise = loadStripe("pk_test_51Nkzt3JL01zwEkK9yDnIWFSA2R84fYtTWWNwaIggtamCb4q6EcJVGyfxNufe8g6izDgatXZV7eBX6CJ9nwtw1aNq00MM18cdQt");
const stripePromise = loadStripe("pk_test_51NECYjSA5wZtMD27VPGqIDhnzp1VDmqIN1fm8RiCZyZ1iAjLlBxSrhs54RVwZmj60YVBWURZDYYCNAt079d2sMs400PXDbRJCL");

const CourseRegistration = () => {
  const { courseId } = useParams();
  const { currentUser } = useAuth();
  const [courseDetails, setCourseDetails] = useState(null);
  const [userId, setUserId] = useState(null);
  const [validationMessage, setValidationMessage] = useState('');
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    country: '',
    email: '',
    experience: '',
    course: ''
  });

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const token = localStorage.getItem('accessToken'); // Adjust this based on where you store the token
        // eslint-disable-next-line no-undef
        if (!token) {
          // console.log('No token found');
          return; // Optionally, handle user redirection to login
        }
  
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const response = await axios.get(`https://dsgbackend.azurewebsites.net/api/me`, config);
  
        if (response.data) {
          // console.log('User ID fetched successfully', response.data);
          setUserId(response.data._id); // Assume your user ID is in the _id field
        }
      } catch (error) {
        // console.error('Failed to fetch user ID:', error);
        // Optionally, handle errors, such as redirecting to an error page or showing an error message
      }
    };
  
    fetchUserId();
  }, []); // Empty dependency array means this effect runs once on component mount
  

  useEffect(() => {
    // console.log('Fetching course and user details');
    const fetchCourseDetails = async () => {
      // console.log(`Fetching details for course ID: ${courseId}`);
      try {
        const response = await axios.get(`https://dsgbackend.azurewebsites.net/api/courses/courses/${courseId}`);
        if (response.data) {
          console.log('Course details fetched successfully', response.data);
          
          setCourseDetails(response.data);
          // Assuming the course's title matches one of the option values in the course select
          setFormData(formData => ({
            ...formData,
            fullName: currentUser?.name || '', 
            email: currentUser?.email || '',
            course: response.data.title||"",
          }));
        }
      } catch (error) {
        // console.error('Failed to fetch course details:', error);
      }
    };


    if (courseId) {
      fetchCourseDetails();
    }
  }, [courseId, currentUser]);

  useEffect(() => {
    // Validate user registration
    const checkUserRegistration = async () => {
      console.log('Starting to validate user registration:', { userId, courseId });
      if (!userId || !courseId) {
        console.log('Missing userId or courseId for validation');
        return;
      }
  
      try {
        console.log(`Checking registration status for userId: ${userId} and courseId: ${courseId}`);
        const validationResponse = await axios.post(`https://dsgbackend.azurewebsites.net/api/validate-user-registration`, {
          userId: userId,
          courseId: courseId,
        });
  
        console.log("Validation response received:", validationResponse.data.message);
        setIsUserRegistered(validationResponse.data.message === "Yes");
  
        if (validationResponse.data.message === "Yes") {
          console.log('User is already registered for the course');
          setValidationMessage('It looks like you are already registered for this course. If you have any questions or wish to enroll in another course, please check our course catalog or contact support.');
        } else {
            console.log('User is not registered for the course');
        }
      } catch (error) {
        console.error('Error checking user registration:', error);
        setIsUserRegistered(false);
      }
    };
  
    if (userId && courseId) {
      checkUserRegistration();
    }
  }, [userId, courseId]);
  


  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(`Form field ${name} updated to: ${value}`);
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submission initiated with data:', formData);
  
    // Storing the entire form data in session storage
    console.log('Storing registration data in session storage.');
    sessionStorage.setItem('registrationData', JSON.stringify(formData));

  // Check if courseDetails has been fetched and contains a courseId
  if (!courseDetails || !courseDetails.courseId) {
    console.error('Course details are not available.');
    setValidationMessage('Failed to fetch course details. Please try again.');
    return; // Stop the process if course details are missing
  }
    
    const stripe = await stripePromise;
    // Using the courseId from the courseDetails state instead of destructuring it from courseDetails again
    const { stripePriceId } = courseDetails;
  
    try {
      console.log('Creating Stripe checkout session');
      // Prepare the data you are about to send to the API
      const checkoutSessionData = {
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        userId: userId,
        priceId: stripePriceId,
        courseId: courseId,
        courseTitle:formData.course,
        country: formData.country,
      };

      console.log('Prepared checkout session data for submission:', checkoutSessionData);
  
      // Storing checkout session data in local storage for later retrieval
      console.log('Storing checkout session data in local storage for later retrieval.');
      localStorage.setItem('checkoutSessionData', JSON.stringify(checkoutSessionData));
  
      console.log('Sending request to server to create Stripe checkout session.');
      const sessionResponse = await axios.post(`https://dsgbackend.azurewebsites.net/api/create-checkout-session`, checkoutSessionData);
  
      const session = sessionResponse.data;
      console.log('Stripe checkout session created:', session);
  
      // Storing the Stripe session details in local storage can be useful for later reference
      console.log('Storing Stripe session details in local storage.');
      localStorage.setItem('stripeSessionDetails', JSON.stringify(session));
      
      // console.log('Redirecting to Stripe checkout.');
      const result = await stripe.redirectToCheckout({ sessionId: session.sessionId });
  
      if (result.error) {
        // console.log('Error during Stripe checkout:', result.error);
        alert(result.error.message);
      } else {
        // console.log('Stripe checkout redirection successful.');
      }
    } catch (error) {
      // console.error('Error during form submission:', error);
      alert('An error occurred while redirecting to checkout.');
    }
  };

  return (
    <div className='courseRegistration'>
      <div className='container'>
        <div className='maintitle-container'>
          <h2 className='title'>Course Registration</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='form-row'> {/* Container for two form groups */}
            <div className='form-group'> {/* First form group */}
              <label htmlFor="fullName">Full Name <span className='star'>*</span></label>
              <input
                id="fullName"
                name="fullName"
                type="text"
                // placeholder="Full Name"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </div>
            <div className='form-group'> {/* Second form group */}
              <label htmlFor="email">Email <span className='star'>*</span></label>
              <input
                id="email"
                name="email"
                type="email"
                // placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className='form-row'> {/* Container for two form groups */}
            <div className='form-group'> {/* Third form group */}
              <label htmlFor="country">Country</label>
              <input
                id="country"
                name="country"
                type="text"
                // placeholder="Country"
                value={formData.country}
                onChange={handleChange}
                required
              />
            </div>
            <div className='form-group'> {/* Fourth form group */}
              <label htmlFor="phoneNumber">Phone Number <span className='star'>*</span> </label>
              <input
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                // placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>
          </div>
         
          <div className='form-row'> {/* Container for select options */}
            {/* <div className='form-group'>
              <label htmlFor="experience">Experience Level</label>
              <select
                id="experience"
                name="experience"
                value={formData.experience}
                onChange={handleChange}
                className='experience'
              >
                <option value="">Select Experience Level</option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advance">Advanced</option>
              </select>
            </div> */}
            <div className='form-group'>
              <label htmlFor="course">Course</label>
              <input
                id="course"
                name="course"
                type="text"
                value={formData.course}
                readOnly
                className='course-input'
              />
            </div>
          </div>
          <button className='proceed-to-pay-button' type="submit" disabled={isUserRegistered}>Proceed to Pay</button>
        </form>
        {validationMessage && <p className="error-message" >{validationMessage}</p>}
      </div>
    </div>
  );
}

export default CourseRegistration;
