const learningPoints = [
    "You will master the Python programming language by building 100 unique projects over 100 days.",
    "You will be able to program in Python professionally.",
    "Create a portfolio of 100 Python projects to apply for developer jobs",
    "Be able to use Python for data science and machine learning",
    "Build GUIs and Desktop applications with Python",
    "You will learn automation, game, app and web development, data science and machine learning all using Python.",
    "You will learn Selenium, Beautiful Soup, Request, Flask, Pandas, NumPy, Scikit Learn, Plotly, and Matplotlib.",
    "Be able to build fully fledged websites and web apps with Python",
    "Build games like Blackjack, Pong and Snake using Python",
    // ... add other points
  ];

  export default learningPoints;