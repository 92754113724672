import React, { useState, useContext, useRef, useEffect } from 'react';
import './page.scss';
// import VIDEO1 from '../../assets/course_videos/12941383-hd_1920_1080_30fps.mp4';
import { useParams, Link } from 'react-router-dom';
import CourseContext from '../../utils/courseContext';
import { ReactComponent as PlayArrow } from '../../assets/svg/PLAY_ARROW.svg';
import { ReactComponent as Pause } from '../../assets/svg/PAUSE.svg';
import { ReactComponent as FullscreenFill } from '../../assets/svg/FULLSCREEN_FILL.svg';
import { ReactComponent as FullscreenExit } from '../../assets/svg/FULLSCREEN_EXIT.svg';
import { ReactComponent as MiniPlayerIcon } from '../../assets/svg/MINI_PLAYER.svg';
import { ReactComponent as VolumeHigh } from '../../assets/svg/VOLUME_HIGH.svg';
import { ReactComponent as VolumeLow } from '../../assets/svg/VOLUME_LOW.svg';
import { ReactComponent as VolumeOff } from '../../assets/svg/VOLUME_OFF.svg';
import Forward10Icon from '@mui/icons-material/Forward10';
import Replay10Icon from '@mui/icons-material/Replay10';

function CourseContent() {
    const resourceURL = 'https://github.com/manideeprkummitha';
    const videoRef = useRef(null);
    const { courseId } = useParams();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [volume, setVolume] = useState(1);
    const [prevVolume, setPrevVolume] = useState(0.5);
    const [isMuted, setIsMuted] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [controlsVisible, setControlsVisible] = useState(true);
    const hideControlsTimeout = useRef(null);
    const [isScrubbing, setIsScrubbing] = useState(false);
    const { selectedLesson, completeLesson } = useContext(CourseContext);

    useEffect(() => {
        if (selectedLesson && selectedLesson.video && videoRef.current) {
            videoRef.current.src = selectedLesson.video;  // Set video source from selected lesson
        }
    }, [selectedLesson]);

    useEffect(() => {
        console.log(completeLesson);  // Check if this logs a function or undefined
        const video = videoRef.current;
    
        const handleVideoEnd = () => {
            setIsPlaying(false);
            showControls();
            if (selectedLesson) {
                completeLesson(selectedLesson._id);
            }
        };
    
        video.addEventListener('ended', handleVideoEnd);
        return () => video.removeEventListener('ended', handleVideoEnd);
    }, [selectedLesson, completeLesson]);

    const handleTimeUpdate = () => {
        if (!isScrubbing) {
            setCurrentTime(videoRef.current.currentTime);
        }
    };

    const handleVideoEnd = () => {
        setIsPlaying(false);
        showControls();
        if (selectedLesson) {
            completeLesson(selectedLesson._id);
        }
    };
    

    useEffect(() => {
        const video = videoRef.current;

        const handleVideoEnd = () => {
            setIsPlaying(false);
            showControls();
            if (selectedLesson) {
                completeLesson(selectedLesson._id);
            }
        };

        const handleLoadedData = () => {
            setDuration(video.duration);
            showControls();
        };

        const handleTimeUpdate = () => {
            if (!isScrubbing) {
                setCurrentTime(video.currentTime);
            }
        };

        video.addEventListener('loadeddata', handleLoadedData);
        video.addEventListener('timeupdate', handleTimeUpdate);
        video.addEventListener('ended', handleVideoEnd);

        return () => {
            video.removeEventListener('loadeddata', handleLoadedData);
            video.removeEventListener('timeupdate', handleTimeUpdate);
            video.removeEventListener('ended', handleVideoEnd);
        };
    }, [isScrubbing]);

    const showControls = () => {
        setControlsVisible(true);
        clearTimeout(hideControlsTimeout.current);
        hideControlsTimeout.current = setTimeout(() => {
            if (isPlaying && !isScrubbing) {
                setControlsVisible(false);
            }
        }, 30000);
    };

    const togglePlay = () => {
        if (videoRef.current.paused || videoRef.current.ended) {
            videoRef.current.play().then(() => {
                setIsPlaying(true);
            }).catch(error => {
                console.error("Playback failed to start:", error);
            });
        } else {
            videoRef.current.pause();
            setIsPlaying(false);
        }
        showControls();
    };


 
    const adjustVolume = (e) => {
        const newVolume = parseFloat(e.target.value);
        videoRef.current.volume = newVolume;
        setVolume(newVolume);
        setIsMuted(newVolume === 0);
        if (newVolume > 0) {
          setPrevVolume(newVolume);
        }
        showControls();
        // Set the CSS variable for the slider
        document.documentElement.style.setProperty('--volume-percentage', `${newVolume * 100}%`);
      };

    const toggleMute = () => {
        const muted = !isMuted;
        setIsMuted(muted);
        if (muted) {
            setPrevVolume(volume);
            setVolume(0);
            videoRef.current.volume = 0;
            videoRef.current.muted = true;
        } else {
            videoRef.current.muted = false;
            videoRef.current.volume = prevVolume || 0.5;
            setVolume(prevVolume || 0.5);
        }
        showControls();
    };

    const changePlaybackRate = (newRate) => {
        setPlaybackRate(newRate);
        videoRef.current.playbackRate = newRate;
    };

    const formatDuration = (time) => {
        const seconds = Math.floor(time % 60);
        const minutes = Math.floor(time / 60) % 60;
        const hours = Math.floor(time / 3600);
        return `${hours ? `${hours}:` : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

   // Mouse event handlers for timeline control
   const handlePlay = () => {
    const playPromise = videoRef.current.play();
    if (playPromise !== undefined) {
        playPromise.then(() => {
            setIsPlaying(true);
        }).catch(error => {
            console.error("Playback failed to start after seeking:", error);
        });
    }
};


  // Adjusted handleScrub and stopScrubbing to fix the interrupted play error
  const handleScrub = (e) => {
    if (!isScrubbing) return;  // Only scrub if the flag is true

    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left; // Get the mouse position relative to the progress bar
    const newTime = (x / rect.width) * duration;  // Calculate the new time based on the mouse position

    videoRef.current.currentTime = newTime;  // Update the video's current time only during scrubbing
    setCurrentTime(newTime);  // Update state to re-render the progress bar
};

const startScrubbing = (e) => {
    e.preventDefault();
    setIsScrubbing(true);  // Set the scrubbing flag to true when mouse is pressed down
};

const stopScrubbing = (e) => {
    if (isScrubbing) {
        handleScrub(e);  // Ensure we update the time when releasing the mouse
        setIsScrubbing(false);  // Reset the scrubbing flag
        if (isPlaying) {
            videoRef.current.play().catch(e => console.error('Error trying to play the video:', e));
        }
    }
};


    const handleKeyPress = (event) => {
        showControls();
        switch (event.key) {
            case "ArrowLeft":
                videoRef.current.currentTime -= 10;
                event.preventDefault();
                break;
            case "ArrowRight":
                videoRef.current.currentTime += 10;
                event.preventDefault();
                break;
            case ' ':
                togglePlay();
                event.preventDefault();
                break;
            case 'f':
                toggleFullScreenMode();
                event.preventDefault();
                break;
            case 'i':
                toggleMiniPlayerMode();
                event.preventDefault();
                break;
            case 'm':
                toggleMute();
                event.preventDefault();
                break;
            default:
                break;
        }
    };

    const toggleFullScreenMode = () => {
        if (!document.fullscreenElement) {
            videoRef.current.parentNode.requestFullscreen().catch(err => {
                console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        } else {
            document.exitFullscreen();
        }
        setIsFullscreen(!isFullscreen);
    };

    const toggleMiniPlayerMode = () => {
        if (!document.pictureInPictureElement) {
            videoRef.current.requestPictureInPicture().catch(err => {
                console.error(`Error attempting to enter Picture-in-Picture mode: ${err.message} (${err.name})`);
            });
        } else {
            document.exitPictureInPicture();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    });

    return (
        <div className='coursecontent'>
            <div className='container'>
                <div className='chaptername'>
                    <span className='name'>{selectedLesson ? selectedLesson.lessonName : 'Select a lesson'}</span>
                </div>
                <div className='video-container' onClick={togglePlay}>
                    {controlsVisible && (
                        <div className='video-controls-container'>
                            <div className='controls'>
                                <div className='left-controllers'>
                                    <button className="play-pause-btn" onClick={togglePlay}>
                                        {isPlaying ? <Pause /> : <PlayArrow />}
                                    </button>
                                    <button className='replay-btn' onClick={() => videoRef.current.currentTime -= 10}>
                                        <Replay10Icon />
                                    </button>
                                    <button className='forward-btn' onClick={() => videoRef.current.currentTime += 10}>
                                        <Forward10Icon />
                                    </button>
                                    <div className="duration-container">
                                        <span className="current-time">{formatDuration(currentTime)}</span> / <span className="total-time">{formatDuration(duration)}</span>
                                    </div>
                                    <div className='volume-container'>
                                        <button className="mute-btn" onClick={toggleMute}>
                                        {isMuted || volume === 0 ? <VolumeOff /> : volume > 0.5 ? <VolumeHigh /> : <VolumeLow />}
                                        </button>
                                        <input className="volume-slider" type="range" min="0" max="1" step="0.01" value={isMuted ? 0 : volume} onChange={adjustVolume}/>
                                    </div>
                                    
                                </div>
                                <div className='right-controllers'>
                                    <select
                                        className="playback-rate-selector"
                                        value={playbackRate}
                                        onChange={(e) => changePlaybackRate(parseFloat(e.target.value))}
                                    >
                                        <option value="0.5">0.5x</option>
                                        <option value="1">1x</option>
                                        <option value="1.25">1.25x</option>
                                        <option value="1.5">1.5x</option>
                                        <option value="1.75">1.75x</option>
                                        <option value="2">2x</option>
                                    </select>
                                    <button className="mini-player-btn" onClick={toggleMiniPlayerMode}>
                                        <MiniPlayerIcon />
                                    </button>
                                    <button className="full-screen-btn" onClick={toggleFullScreenMode}>
                                        {isFullscreen ? <FullscreenExit /> : <FullscreenFill />}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div
                        className="timeline-container"
                        onMouseDown={(e) => { e.preventDefault(); setIsScrubbing(true); }}
                        onMouseMove={isScrubbing ? handleScrub : undefined}
                        onMouseLeave={isScrubbing ? stopScrubbing : undefined}
                        onMouseUp={stopScrubbing}
                    >
                        <div className="progress-bar" style={{ width: `${(currentTime / duration) * 100}%` }}></div>
                    </div>
                    <video
                            ref={videoRef}
                            // src={VIDEO1}
                            onDoubleClick={toggleFullScreenMode}
                            onTimeUpdate={handleTimeUpdate}
                            onEnded={handleVideoEnd}
                            // Added loadedmetadata event listener to get the video duration
                            onLoadedMetadata={() => setDuration(videoRef.current.duration)}
                            />
                </div>
                <div className='buttons'>
                    <Link to={resourceURL} target="_blank" rel="noopener noreferrer" className='resource-button'>
                        View Resources
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default CourseContent;
