import React from 'react';
import "./WhoWeAre.scss";
import who_are_we_image from "../../assets/who_are_we_image.svg";

function WhoWeAre() {
  return (
    <div className="who-we-are">
    <div className='who-we-are-container'>
      <div className='who-we-are-text'>
        <div className='who-we-are-maintitle'>
            Who we are?
        </div>
        <div className='who-we-are-subtitle'>
        We're dedicated to helping you grow your skills and 
        learn from anywhere. Whether you're just starting out 
        or looking to advance your career, our industry-relevant 
        and beginner-friendly courses cover 
        a wide range of topics. Our course's are
        </div>
        <div className='who-we-are-points'>
            <div className='points'>
                <div className='point-one'>Beginner Friendly Courses</div>
                <div className='point-two'>Industry Relevant Courses</div>
            </div>
            <div className='points'>
                <div className='point-one'>Advanced Level Courses</div>
                <div className='point-two'>At Affordable Prices</div>
            </div>
        </div>
      </div>
      <div className='who-we-are-image-container'>
        <img src={who_are_we_image} alt="" className='who-we-are-image'/>
      </div>
    </div>
</div>
  )
}

export default WhoWeAre
