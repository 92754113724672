import React from 'react';
import { Link } from 'react-router-dom';
import "./CourseListingCard.scss";

const CourseListingCard = ({ courseId, courseImage, courseTitle, isInsideDashboard, isLoading }) => {
    const linkPath = isLoading ? '#' : (isInsideDashboard ? `/dashboard/course/${courseId}` : `/course/${courseId}`);
    
    if (isLoading) {
        return (
            <div className="card skeleton">
                <div className="skeleton-image"></div>
                <div className="skeleton-title"></div>
            </div>
        );
    } else {
        return (
            <Link to={linkPath} className="card">
                <img className="courseImage" src={courseImage} alt={courseTitle} />
                <div className="courseTitle">{courseTitle}</div>
            </Link>
        );
    }
};

export default CourseListingCard;
