import React, { useRef } from 'react';
import "./home.scss";
// import HeroSection from '../../components/home_hero_section/HeroSection';
import CTA from '../../components/CTA/CTA.jsx';
import Features from '../../components/features/Features';
import Testimonials from '../../components/testimonials/Testimonials';
import HeroSection2 from '../../components/heroSection/HeroSection';
import WhoWeAre from '../../components/whoweare/WhoWeAre';
// import CourseSlider from '../../components/courseSldier/CoruseSlider';

function Home() {
  
  return (
    <div className='home'>
        <HeroSection2/>
        <WhoWeAre/>
       <Features/>
       <Testimonials/>
       <CTA/>
    </div>
  )
}

export default Home
