import React from 'react';
import "./WhyDSG.scss";

const whyDataStudyGuidePoints = [
    {
      title: "Thriving Developer Community",
      description: "Engage in discussions, share insights, and access resources. Foster collaboration and enhance your data science skills. Leverage collective knowledge to drive forward your projects and ideas."
    },
    {
      title: "Mentors",
      description: "Learn from experienced professionals with extensive backgrounds in real-world data challenges. Benefit from mentorship that offers practical insights and guidance to navigate complex data scenarios."
    },
    {
      title: "Industry-Relevant Curriculum",
      description: "Stay updated with a curriculum that adapts to the fast-paced world of data science and analytics. Access cutting-edge topics and methodologies essential for success in the ever-evolving data landscape."
    },
    {
      title: "Project-Based Learning",
      description: "Learn through practical application – our meticulously crafted projects offer real-world use cases, enabling you to develop a robust portfolio and acquire relevant skills essential for a seamless transition into the workspace."
    }
  ];

  function WhyDSG() {
    // Split the points into two groups for side by side display
    const firstColumnPoints = whyDataStudyGuidePoints.slice(0, 2);
    const secondColumnPoints = whyDataStudyGuidePoints.slice(2);
  
    return (
      <div className='whyDSG'>
        <div className='container'>
          <h2>Why Data Study Guide?</h2>
          <div className='points'>
            <div className='points-column'>
              {firstColumnPoints.map((point, index) => (
                <div key={index} className='point-item'>
                  <h3 className='point-title'>{point.title}</h3>
                  <p className='point-description'>{point.description}</p>
                </div>
              ))}
            </div>
            <div className='points-column'>
              {secondColumnPoints.map((point, index) => (
                <div key={index} className='point-item'>
                  <h3 className='point-title'>{point.title}</h3>
                  <p className='point-description'>{point.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
  

export default WhyDSG
