import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios'; // Ensure axios is imported for API calls
import "./PurchasedCourseCard.scss"; // Import your styles

function PurchasedCourseCard({ courseId }) {
  const [loading, setLoading] = useState(true);
  const [courseDetails, setCourseDetails] = useState(null); // Start with null to indicate no data yet

  useEffect(() => {
    // console.log(`Effect running for courseId: ${courseId}`);

    // Function to fetch course details from the API
    const fetchCourseDetails = async () => {
      // console.log(`Fetching course details for ID: ${courseId}`);
      setLoading(true); // Set loading true when the fetch starts
      try {
        const response = await axios.get(`https://dsgbackend.azurewebsites.net/api/courses/courses/${courseId}`);
        // console.log(`Fetch successful for courseId: ${courseId}`, response.data);
        
        if (response.data) {
          setCourseDetails({
            title: response.data.title,
            mainImage: response.data.mainImage,
          });
          // console.log(`Course details set for courseId: ${courseId}`);
        }
      } catch (error) {
        // console.error(`Failed to fetch course details for courseId: ${courseId}`, error);
      } finally {
        setLoading(false); // Ensure loading state is updated regardless of the request outcome
        // console.log(`Loading finished for courseId: ${courseId}`);
      }
    };

    fetchCourseDetails();
  }, [courseId]); // Depend on courseId so it refetches if the prop changes

  if (loading || !courseDetails) { // Check if loading or if there are no course details
    // console.log(`Rendering loading/skeleton state for courseId: ${courseId}`);
    return (
      <div className='purchasedCourseCard loading'>
        <div className='image-container'>
          <div className='course-image skeleton'></div>
        </div>
        <div className='course-details'>
          <span className='course-title skeleton'></span>
          <div className='course-button skeleton'></div>
          <div className='invoice-button skeleton'></div>
        </div>
      </div>
    );
  }

  // console.log(`Rendering course details for courseId: ${courseId}`);
  return (
    <div className='purchasedCourseCard'>
        <div className='image-container'>
            <img className='courseimage' src={courseDetails.mainImage} alt={courseDetails.title} />
        </div>
        <div className='course-details'>
            <span className='course-title'>{courseDetails.title}</span>
            {/* Adjust or uncomment the invoice button as per your application's needs */}
            {/* <button className='invoice-button'>
                <Link to={`/dashboard/invoice/${courseId}`} className='link'>Invoice</Link>
            </button> */}
        </div>
    </div>
  );
}

export default PurchasedCourseCard;
