import React from 'react';
import "./cta.scss";
import { Link } from 'react-router-dom';

function CTA() {
  return (
    <div className='cta'>
        
        <div className='content-container'> 
           <span className='line1'>Be a part of new era in <span className='line1-subtext'>Programming</span></span>
           <span className='line2'>Excel beyond limits with <span className='line2-subtext'>Data Study Guide</span></span>
           <Link to="/login" className='join-us-link'>
                <button className='button'>Join Us</button>
           </Link>
        </div>
        
    </div>
  )
}

export default CTA
