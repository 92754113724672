import React, { useState } from 'react';
import "./register.scss";
import { Link, useNavigate } from 'react-router-dom';
import LOGIN from "../../assets/LOGIN.svg";
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import { useAuth } from '../../context/AuthContext';

function Register() {
    const navigate = useNavigate();
    const {googleSignIn} = useAuth();
    const [loginError, setLoginError] = useState('');

    const handleGoogleSuccess = async (credentialResponse) => {
      try {
          await googleSignIn(credentialResponse.credential);
          // console.log('Google login successful');
          navigate("/dashboard/courses");
        } catch (error) {
          // console.error('Google login failed:', error);
          setLoginError('Google login failed. Please try again later.');
      }
  };

  const handleGoogleError = () => {
      setLoginError('Google login failed. Please try again later.');
  };

  return (
    <div className='register'>
      <div className='container'>
        <div className='register-left-side'>
            <img className='image' src={LOGIN} alt=""/>
        </div>
        <div className='register-form'>
          <div className='register-top-section'>
            <div className='register-form-header'>
                <h2>Create a new account</h2>
                <span>
                    Already have an account? <Link to="/login" className="link">Sign in</Link>
                </span>
            </div>
            <div className='register-buttons'>
                  <Link to="/continue-with-email-password/register" className='email-password'>
                          <button className='link'>Continue with Email</button>
                    </Link>
                    {/* <div className='email-password'>
                        <Link className='link'>
                            Continue with Google
                        </Link>
                    </div> */}
                    <div className="login-with-google">
                    <GoogleLogin
                        onSuccess={handleGoogleSuccess}
                        onError={handleGoogleError}
                        render={(renderProps) => (
                            <button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            className="custom-google-login-button"
                            >
                            {/* Your custom styles and content for the button */}
                            Sign in with Google
                            </button>
                        )}
                        />
                    </div>
            </div>
          </div>
          <span className="login-subtitle">
                By joining, you agree to the Data Study Guide Terms & Conditions and to occasionally receive emails from us. Please read our Privacy Policy to learn how we use your personal data.
            </span>
        </div>
      </div>
    </div>
  )
}

export default Register
