import React from 'react';
import { Link } from 'react-router-dom';
import "./contactUs.scss"; // Import your stylesheet for CustomerSupport

function ContactUs() {
  return (
    <div className="customer-support">
      <div className="container">
        <div className="content">
          <span className='title'>Customer Support</span>
          <p>Welcome to DataStudyGuide's Customer Support. We are here to assist you with any questions, concerns, or issues you may encounter while using our platform.</p>

          <h2 className='contact-us'>Contact Us</h2>
          <p>If you need assistance, have feedback to share, or require clarification on any matter, please do not hesitate to reach out to us at <a href="mailto:info@datastudyguide.com">info@datastudyguide.com</a>. Our dedicated support team is committed to ensuring your experience with DataStudyGuide is smooth and enriching.</p>

          <p>Your satisfaction is our priority, and we look forward to helping you make the most of your data learning journey.</p>

          <p>Thank you for choosing DataStudyGuide!</p>
        </div>

        <div className="connectButton">
          <button className="connectbutton">
            <Link to="/" className="link">Back to Home</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;