import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import { useGoogleOneTapLogin } from '@react-oauth/google';
import Navbar from "./components/navbar/Navbar.jsx";
import Home from './pages/home/Home.jsx';
import Courses from './pages/courses/Courses.jsx';
import Footer from './components/footer/Footer.jsx';
import Login from './pages/login/Login.jsx';
import ForgotPassword from './pages/forgotpassword/ForgotPassword.jsx';
import Register from './pages/register/Register.jsx';
import ResetPassword from './pages/resetpassword/ResetPassword.jsx';
import EmailPassword from './pages/emailPassword/EmailPassword.jsx';
import Userdashboard from './pages/userDashboard/Userdashboard.jsx';
import CourseDetails from './pages/courseDetails/CourseDetails.jsx';
import Purchase from "./pages/dashboard/purchases/Purchase.jsx";
import Settings from './pages/dashboard/settings/Settings.jsx';
import DashboardCourses from './pages/dashboard/courses/Courses.jsx';
import CourseRegisteration from './pages/courseRegisteration/CourseRegisteration.jsx';
import AboutUs from './pages/aboutus/AboutUs.jsx';
import ContactUs from './pages/contactUs/ContactUs.jsx';
import TermsAndConditions from './pages/termsandconditions/Termsandconditions.jsx';
import PrivacyPolicy from './pages/privacypolicy/Privacypolicy.jsx';
import RefundPolicy from './pages/refundpolicy/Refundpolicy.jsx';
import Success from './pages/success/Success.jsx';
import FailedPayment from './pages/failedPayment/FailedPayment.jsx';
import ChatWithUs from './components/chat-with-us/Chat_with_us.jsx';
import ScrollToTop from './utils/scrollTop.jsx';
import CourseContent from "./pages/courseContent/CourseContent.jsx"
import './App.css';
import PAGE from "./pages/PAGE/page.jsx";

import { ProtectedRoute } from './context/ProtectedRoutes.jsx'; // Ensure this path is correct

// Define a layout component for applying common layout (e.g., Navbar and Footer) to a group of routes
const Layout = () => (
  <div className="app">
    <div className="app">
      <ScrollToTop /> 
      <Navbar />
      <div className="content-wrap"> {/* Add this wrapper */}
        <Outlet />
      </div>
      <ChatWithUs/>
      <Footer />
    </div>
  </div>
);

// Use a function to dynamically wrap components with ProtectedRoute where needed
const protectedRoute = (Component) => (
  <ProtectedRoute>
    <Component />
  </ProtectedRoute>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: "courses", element: <Courses /> },
      { path: "course/:courseId", element: <CourseDetails /> },
      { path: "login", element: <Login /> },
      { path: "register", element: <Register /> },
      { path: "forgotpassword", element: <ForgotPassword /> },
      { path: "resetpassword", element: <ResetPassword /> },
      { path: "continue-with-email-password", element: <EmailPassword action="login" /> },
      { path: "continue-with-email-password/register", element: <EmailPassword action="register" /> },
      { path: "course-registration", element: <CourseRegisteration/>},
      { path: "about-us", element: <AboutUs /> },
      { path: "contact-us", element: <ContactUs /> },
      { path: "terms-and-conditions", element: <TermsAndConditions /> }, // Added route for Terms and Conditions
      { path: "privacy-policy", element: <PrivacyPolicy /> }, // Added route for Privacy Policy
      { path: "refund-policy", element: <RefundPolicy /> }, 
      { path: "success-page", element:<Success/>},
      { path: "failure-page", element:<FailedPayment/>},
      {
        path: "dashboard/course/:courseId",
        element: protectedRoute(CourseDetails),
      },
     ],
  },
  {
    path:"course-registration/:courseId",
    element:protectedRoute(CourseRegisteration)
  },
  {
    path: "dashboard",
    element: protectedRoute(Userdashboard),
    children: [
      { path: "purchase", element: protectedRoute(Purchase) },
      { path: "settings", element: protectedRoute(Settings) },
      { path: "courses", element: protectedRoute(DashboardCourses) },
      { path: "course-content/:courseId",element:protectedRoute(CourseContent)},
      { path: "page/:pageId",element:protectedRoute(PAGE)},

    ],
  },
]);

function App() {
  // Include your Google One Tap Login logic here if necessary
  return <RouterProvider router={router} />;
}

export default App;