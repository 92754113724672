import React, { useState, useEffect } from 'react';
import "./Courses.scss";
import CourseListingCard from '../../../components/courseListingCard/CourseListingCard.jsx';
// Assuming the API's base URL is known and CORS is configured
const BASE_URL = 'https://dsgbackend.azurewebsites.net'; // Update this with your actual API base URL

function Courses() {
  const [searchQuery, setSearchQuery] = useState('');
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state


  useEffect(() => {
    const fetchCourses = async () => {
      // console.log('Fetching courses from API...');
      try {
        const response = await fetch(`${BASE_URL}/api/courses/getallcourses`);
        if (response.ok) {
          const data = await response.json();
          // console.log('Fetched courses:', data);
          setCourses(data);
          setFilteredCourses(data);
        } else {
          throw new Error('Response not ok');
        }
      } catch (error) {
        // console.error('Failed to fetch courses:', error);
      }finally {
        setIsLoading(false); // End loading
      }
    };
    fetchCourses();
  }, []);

  useEffect(() => {
    // console.log('Filtering courses based on search query:', searchQuery);
    const result = courses.filter(course =>
      (course.courseName?.toLowerCase() || '').includes(searchQuery.toLowerCase())
    );
    // console.log('Filtered courses:', result);
    setFilteredCourses(result);
  }, [searchQuery, courses]);
  
  return (
    <div className='coursePage'>
      <div className='container'>
        <h2>Courses</h2>
        <div className='courseCards'>
          {isLoading ? (
            // Render skeleton cards when loading
            Array.from({ length: 5 }).map((_, index) => (
              <CourseListingCard key={index} isLoading={true} />
            ))
          ) : (
            // Render actual course cards
            filteredCourses.map((course, index) => (
              <CourseListingCard
                key={course._id || index}
                courseId={course._id}
                courseImage={course.mainImage} 
                courseTitle={course.title}
                isInsideDashboard={true}
                isLoading={false}
              />
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default Courses
