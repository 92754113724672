import React, { useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import axios from 'axios';
import "./EmailPassword.scss";
import JOIN_NOW from "../../assets/LOGIN1.svg"
import { useAuth } from '../../context/AuthContext';

function EmailPassword() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { login, register } = useAuth();

  const pathIncludesRegister = location.pathname.includes('/continue-with-email-password/register');

  const validateForm = () => {
    let isValid = true;
    setEmailError('');
    setPasswordError('');

    if (!email) {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setEmailError("Invalid email address.");
      isValid = false;
    }

    if (!password) {
      setPasswordError("Password is required.");
      isValid = false;
    } else if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters long.');
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const pathIncludesRegister = location.pathname.includes('/continue-with-email-password/register');
    try {
      if (pathIncludesRegister) {
        // Use the register function from AuthContext
        await register(email, password);
        // console.log('Registration successful');
      } else {
        // Use the login function from AuthContext
        await login(email, password);
        // console.log('Login successful');
      }
      navigate('/dashboard'); // Adjust as necessary
    } catch (error) {
      // console.error(`${pathIncludesRegister ? 'Registration' : 'Login'} failed:`, error);
      setLoginError(`${pathIncludesRegister ? 'Registration' : 'Login'} failed. Please try again.`);
    }
  };

  return (
    <div className='emailPassword'>
      <div className='container'>
      <div className='emailPassword-left-side'>
            <img className='image' src={JOIN_NOW} alt=""/>
        </div>
        <div className='emailPassword-form'>
          <div className='emailPassword-top-section'>
          <div className='register-form-header'>
                    <h2>Continue with your email</h2>
          </div>
            <div className='form'>
              {/* TextField and Button components */}
              <TextField
                error={!!emailError}
                helperText={emailError}
                label="Email"
                type="email"
                variant="filled"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                error={!!passwordError}
                helperText={passwordError}
                label="Password"
                type="password"
                variant="filled"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
                {!pathIncludesRegister && (
                <Link to="/forgotPassword" className='link'>Forgot Password?</Link>
              )}
              {loginError && <div className='error-message'>{loginError}</div>}
              <Button
                className='button'
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: '20px' }}
                onClick={handleSubmit} // Use onClick for the div-based submission
              >
                {location.pathname.includes('/continue-with-email-password/register') ? 'Register' : 'Login'}
              </Button>
            </div>           
          </div> 
          <span className="login-subtitle">
              By joining, you agree to the Data Study Guide Terms & Conditions and to occasionally receive emails from us. Please read our Privacy Policy to learn how we use your personal data.
            </span>          
        </div>
      </div>
    </div>
  );
}

export default EmailPassword;
