import React from 'react';
import "./Features.scss";
import CERTIFICATE from "../../assets/certificate.svg"
import STUDENT from "../../assets/student.svg"
import VEDIO_COURSE from "../../assets/video course.svg"
import PROFILE from "../../assets/profile.svg"
function Features() {
  return (
    <div className='features'>
      <div className='container'>
        <div className='features-line'>
            <div className='point1'>
                <img src={CERTIFICATE} alt="" className='point-image'/>
                <span className='point-text'>Online Certification</span>
            </div>
            <div className='point2'>
            <img src={STUDENT} alt="" className='point-image'/>
                <span className='point-text'>Industry Relevance</span>
            </div>
        </div>
        <div className='features-line'>
            <div className='point1'>
            <img src={VEDIO_COURSE} alt="" className='point-image'/>
                <span className='point-text'>Hands on Experience</span>
            </div>
            <div className='point2'>
            <img src={PROFILE} alt="" className='point-image'/>
                <span className='point-text'>Community Access</span>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Features
