import React, { useState } from 'react';
import ARROW from "../../assets/chevron-right.svg";
import DOWN_ARROW from "../../assets/chevron-down.svg";
import "./faqCard.scss";

function FaqCard({faq}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    // console.log("Current state before toggle: ", isOpen);
    setIsOpen(!isOpen);
    // console.log("Current state after toggle: ", !isOpen);
  };

  return (
    <div className='faq-card'>
        <div className='faq-info' onClick={toggleOpen}>
          <div className='question-faqcard'>
            <div className='toggle-button'>
              {isOpen ? <img src={DOWN_ARROW} alt="downward arrow" /> : <img src={ARROW} alt="right arrow" />}
            </div>
            <h2 className='question'>{faq.question}</h2>
          </div>
          
        </div>
        {isOpen && (
            <div className='faq-contents' style={{ display: isOpen ? 'block' : 'none' }}> 
              <span className='answer-faqcard'>{faq.answer}</span>
            </div>
          )}
    </div>
  );
}

export default FaqCard;
